import axios from 'axios';
import { baseUrl } from '@/utils/global'


export function downloadGet(api,fileName) {
    axios({
        method: "get",
        url: api,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
        baseURL: baseUrl,
        responseType: 'blob',
    }).then(res => {
        const blob = new Blob([res.data]);
        //对于<a>标签，只有 Firefox 和 Chrome（内核） 支持 download 属性
        //IE10以上支持blob，但是依然不支持download
        if ('download' in document.createElement('a')) {
            //支持a标签download的浏览器
            const link = document.createElement('a');//创建a标签
            link.download = fileName;//a标签添加属性
            link.style.display = 'none';
            link.href = URL.createObjectURL(blob);
            document.body.appendChild(link);
            link.click();//执行下载
            URL.revokeObjectURL(link.href); //释放url
            document.body.removeChild(link);//释放标签
        } else {
            navigator.msSaveBlob(blob, fileName);
        }
    }).catch((res) => {
        this.$message.warning({ message: res });
    });
}

export function downloadPost(api,data,fileName) {
    axios({
        method: "post",
        url: api,
        headers: {
            'Content-Type': 'application/json;charset=UTF-8'
        },
        baseURL: baseUrl,
        responseType: 'blob',
        data:data
    }).then(res => {
        const blob = new Blob([res.data]);
        //对于<a>标签，只有 Firefox 和 Chrome（内核） 支持 download 属性
        //IE10以上支持blob，但是依然不支持download
        if ('download' in document.createElement('a')) {
            //支持a标签download的浏览器
            const link = document.createElement('a');//创建a标签
            link.download = fileName;//a标签添加属性
            link.style.display = 'none';
            link.href = URL.createObjectURL(blob);
            document.body.appendChild(link);
            link.click();//执行下载
            URL.revokeObjectURL(link.href); //释放url
            document.body.removeChild(link);//释放标签
        } else {
            navigator.msSaveBlob(blob, fileName);
        }
    }).catch((res) => {
        this.$message.warning({ message: res });
    });
}