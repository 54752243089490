<template>
  <div class="container" :class="$store.state.app.isCollapse ? 'menu-bar-collapse-width' : 'menu-bar-width'">
    <!-- <BreadCrumb></BreadCrumb> -->
    <transition name="fade" mode="out-in">
      <router-view></router-view>
    </transition>
  </div>
</template>

<script>
export default {
  data() {
    return {
    };
  },
  methods: {

  },
  mounted() {

  }
};
</script>

<style scoped lang="scss">
.container {
  position: absolute !important;
  top: 60px !important;
  bottom: 0px !important;
  left: 220px !important;
  right: 0px !important;
  //margin: 20px !important;
  transition: left .3s;
}

.menu-bar-width {
  left: 220px !important;
}

.menu-bar-collapse-width {
  left: 60px !important;
}
</style>