<template>
    <div :class="className" :style="{ height: height, width: width }" />
</template>

<script>
import * as echarts from 'echarts'
require('echarts/theme/macarons') // echarts theme
import resize from './mixins/resize'
import { getNetworkingPieChart } from "@/api/networking"


export default {
    mixins: [resize],
    props: {
        className: {
            type: String,
            default: 'chart'
        },
        width: {
            type: String,
            default: '100%'
        },
        height: {
            type: String,
            default: '350px'
        }
    },
    data() {
        return {
            chart: null,
            seriesData: [
                { value: 10, name: 'AA' },
                { value: 10, name: 'BB' },
                { value: 10, name: 'CC' },
            ],
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.setSeriesData();
            //this.initChart();
        })
    },
    beforeDestroy() {
        if (!this.chart) {
            return
        }
        this.chart.dispose()
        this.chart = null
    },
    methods: {
        initChart() {
            this.chart = echarts.init(this.$el, 'macarons')

            this.chart.setOption({
                tooltip: {
                    trigger: 'item',
                    formatter: '{a} <br/>{b} : {c} ({d}%)'
                },
                legend: {
                    left: 'center',
                    bottom: '10',
                    //data: this.legendData
                },
                series: [
                    {
                        name: 'Networking',
                        type: 'pie',
                        roseType: 'radius',
                        radius: [15, 95],
                        center: ['50%', '38%'],
                        data: this.seriesData,
                        animationEasing: 'cubicInOut',
                        animationDuration: 2600
                    }
                ]
            })
        },
        setSeriesData() {
            getNetworkingPieChart().then(res => {
                this.seriesData = res.data;
            }).catch(res => {

            }).finally(res => {
                this.initChart();
            })
        },
    }
}
</script>
