import request from '@/http/request'


//获取分页数据
export function getSupportFileByPage(data) {
    return request({
        url: '/support-file/get-by-page',
        method: 'post',
        data
    })
}

export function addSupportFile(data) {
    return request({
        url: '/support-file/add',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data
    })
}

export function updateSupportFile(data) {
    return request({
        url: '/support-file/update',
        method: 'post',
        data
    })
}

export function updateSupportFileAll(data) {
    return request({
        url: '/support-file/update-file',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data
    })
}

export function deleteSupportFile(data) {
    return request({
        url: '/support-file/delete',
        method: 'post',
        data
    })
}

//获取所有数据
export function getSupportFileList() {
    return request({
        url: '/support-file/list',
        method: 'get',
    })
}

//根据id查询单条数据
export function getSupportFileInfo(data) {
    return request({
        url: '/support-file/info',
        method: 'post',
        data
    })
}

//验证名称是否存在
export function validateNameExist(data) {
    return request({
        url: '/support-file/validate',
        method: 'post',
        data
    })
}

//下载
export function downloadFile(data) {
    return request({
        url: '/support-file/download',
        method: 'post',
        responseType: 'blob',
        data
    })
}

export function getSupportFileLikeName(data){
    return request({
        url: '/support-file/get-like-name',
        method: 'post',
        data
    })
}