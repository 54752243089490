import request from '@/http/request'



/**************************** Cpu **************************** */
// 获取CPU种类作为筛选条件
export function getCpuList () {
    return request({
        url: '/cpu/list',
        method: 'get',
    })
}

export function getCpuByPage (data) {
    return request({
        url: '/cpu/get-by-page',
        method: 'post',
        data
    })
}

export function addCpu (data) {
    return request({
        url: '/cpu/add',
        method: 'post',
        data
    })
}

export function updateCpu (data) {
    return request({
        url: '/cpu/update',
        method: 'post',
        data
    })
}

export function deleteCpu (data) {
    return request({
        url: '/cpu/delete',
        method: 'post',
        data
    })
}

export function validateCpuExist(data){
    return request({
        url: '/cpu/validate',
        method: 'post',
        data
    })
}