<template>
    <div id="app">

        <!-- 查询表单 -->
        <el-form :inline="true" :model="queryForm" class="demo-form-inline">
            <el-form-item label="">
                <el-input v-model="queryForm.aspectRatio" @blur="queryForm.aspectRatio = $event.target.value.trim()"
                    :placeholder="$t('table.aspectRatio')" auto-complete="off" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleQuery()">{{ $t('table.search') }}</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleAdd()" v-if="permiss">{{ $t('table.add') }}</el-button>
            </el-form-item>
        </el-form>

        <!-- 数据表格 -->
        <el-table :data="aspectRatioList" border :stripe="true" style="width: 100%" max-height="590"
            @selection-change="handleSelectionChange" highlight-selection-row highlight-current-row>
            <el-table-column type="selection" min-width="10%">
            </el-table-column>
            <el-table-column type="index" label="#" :index="handleIndex" min-width="10%">
            </el-table-column>
            <el-table-column prop="aspectRatio" :label="$t('table.aspectRatio')" min-width="20%"
                :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="createTime" :label="$t('table.createTime')" min-width="20%"
                :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="updateTime" :label="$t('table.updateTime')" min-width="20%"
                :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column :label="$t('table.operation')" min-width="20%" v-if="permiss">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">{{ $t('table.edit') }}</el-button>
                    <el-button size="mini" type="danger"
                        @click="handleDelete(scope.$index, scope.row)">{{ $t('table.delete') }}</el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- 工具栏 -->
        <div class="toolbar">
            <el-button type="danger" size="small" @click="handleDeletes()"
                v-if="permiss">{{ $t('table.deletes') }}</el-button>
            <!-- 分页列表 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
                :current-page="pageNum" :page-sizes="[10, 20, 50]" :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>

        <el-dialog :title="operation ? $t('table.add') : $t('table.edit')" :visible.sync="editFormVisible"
            @close="handleClose()" :destroy-on-close="true">
            <el-form ref="editForm" :model="editForm" :rules="editRules" label-position="right" label-width="100px">
                <el-form-item :label="$t('table.aspectRatio')" prop="aspectRatio">
                    <el-col :span="11">
                        <el-input :placeholder="$t('table.width')" prop="width"
                            @blur="editForm.width = $event.target.value.trim()" auto-complete="off" maxlength="2"
                            v-model="editForm.width" oninput="value=value.replace(/[^\d]/g,'')"
                            style="width: 100%;"></el-input>
                    </el-col>
                    <el-col class="line" :span="2">&emsp;&nbsp;:</el-col>
                    <el-col :span="11">
                        <el-input :placeholder="$t('table.height')" prop="height"
                            @blur="editForm.height = $event.target.value.trim()" auto-complete="off" maxlength="2"
                            v-model="editForm.height" oninput="value=value.replace(/[^\d]/g,'')"
                            style="width: 100%;"></el-input>
                    </el-col>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editFormVisible = false">{{ $t('table.cancel') }}</el-button>
                <el-button type="primary" @click="handleEditSubmit('editForm')">{{ $t('table.confirm') }}</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import {
    getAspectRatioByPage, deleteAspectRatio, updateAspectRatio, addAspectRatio, validateAspectRatioExist
} from "@/api/advertising/aspectRatio"

export default {
    name: 'admin-aspectRatio',
    computed: {
        editRules() {
            const validateAspectRatio = (rule, value, callback) => {
                if (this.editForm.width != '' & this.editForm.height != '') {
                    value = this.editForm.width + " : " + this.editForm.height;
                    if (this.oldAspectRatio != value) {
                        let param = {
                            aspectRatio:value
                        }
                        validateAspectRatioExist(param).then(res => {
                            if (!res.data) {
                                callback(new Error(this.$t('rules.aspectRatioExist')))
                            } else {
                                this.editForm.aspectRatio = value;
                                callback()
                            }
                        }).catch(function (res) {
                            callback(new Error(this.$t('rules.exception')))
                        });
                    }
                    else {
                        this.editForm.aspectRatio = value;
                        callback()
                    }
                } else {
                    if (this.editForm.width == '' & this.editForm.height == '') {
                        callback(new Error(this.$t('rules.inputWidthAndheight')))
                    } else if (this.editForm.width == '') {
                        callback(new Error(this.$t('rules.inputWidth')))
                    } else if (this.editForm.height == '') {
                        callback(new Error(this.$t('rules.inputHeight')))
                    }
                }
            }
            return{
                aspectRatio: [
                    { validator: validateAspectRatio, trigger: 'blur' },
                ],
            } 
        }
    },
    data() {

        return {
            loginRole: "",
            permiss: false,
            aspectRatioList: [],
            queryForm: {
                aspectRatio: '',
            },
            pageNum: 1,
            total: 10,
            pageSize: 10,
            pages: 1,
            deleteIds: [],
            operation: true,
            editFormVisible: false,
            editForm: {
                id: '',
                aspectRatio: '',
                width: '',
                height: '',
            },
            oldAspectRatio: '',
        }
    },
    mounted() {
        this.getSession();
        this.setAspectRatioList();
    },
    methods: {
        getSession() {
            this.loginRole = this.$cookies.get("role");
            if (this.loginRole == "super admin" || this.loginRole == "advertising admin") {
                this.permiss = true;
            } else {
                this.permiss = false;
            }
        },
        handleIndex(index) {
            const pageNum = this.pageNum // 当前页码
            const pageSize = this.pageSize // 每页条数
            return index + 1 + (pageNum - 1) * pageSize
        },
        setAspectRatioList() {
            let params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                queryForm: this.queryForm
            };
            getAspectRatioByPage(params).then((res) => {
                this.aspectRatioList = res.data.content;
                this.total = res.data.totalSize;
            }).catch((res) => { });
        },
        delete(ids) {
            this.$confirm(this.$t('message.deleteConfirm'), this.$t('message.tip'), {
                type: "warning"
            }).then(() => {
                deleteAspectRatio(ids).then((res) => {
                    if (res.data >= 1) {
                        this.$message.success({ message: this.$t('message.deleteSuccess') });
                        this.setAspectRatioList();
                    } else {
                        let msgList = [];
                        if(res.data.Advertising != null && res.data.Advertising != "" ) msgList.push("Advertising : [ " + res.data.Advertising + " ]");

                        let message = "";
                        if (msgList != null && msgList.length > 0) {
                            message = this.$t('message.deleteReferencedData')+`<br/><br/>${msgList.join(
                                "<br/><br/>"
                            )}`;
                        }else{
                            message = res.data;
                        }
                        
                        this.$message({
                            dangerouslyUseHTMLString: true,
                            message: message,
                            type: "warning"
                        });
                    }
                }).catch((res) => {
                    this.$message.warning({ message: res });
                });
            }).catch(() => {
                this.$message.info({ message: this.$t('message.deleteCancel') });
            });
        },
        handleDelete(index, row) {
            let deleteId = [row.id];
            this.delete(deleteId);
        },
        handleDeletes() {
            if (this.deleteIds.length == 0) {
                this.$message.info({ message: this.$t('message.deleteDataSelect') });
            } else {
                this.delete(this.deleteIds);
            }
        },
        handleSelectionChange(val) {
            this.deleteIds = [];
            val.forEach(item => {
                this.deleteIds.push(item.id);
            });
        },
        handleQuery() {
            this.pageNum = 1;
            this.setAspectRatioList();
        },
        handleSizeChange(val) {
            if (this.pageSize != val) {
                this.pageSize = val;
                this.pageNum = 1;
                this.setAspectRatioList();
            }
        },
        handleCurrentChange(val) {
            this.pageNum = val;
            this.setAspectRatioList();
        },
        handleAdd() {
            this.operation = true;
            this.editFormVisible = true;
        },
        handleClose() {
            Object.keys(this.editForm).forEach(key => (this.editForm[key] = ''));
            this.oldAspectRatio = '';
        },
        handleEdit(index, row) {
            this.operation = false;
            this.editFormVisible = true;
            this.editForm.id = row.id;
            this.editForm.aspectRatio = row.aspectRatio;
            this.editForm.width = row.aspectRatio.substring(0, row.aspectRatio.indexOf(":") - 1);
            this.editForm.height = row.aspectRatio.substring(row.aspectRatio.indexOf(":") + 2, row.aspectRatio.length);
            this.oldAspectRatio = row.aspectRatio;
        },
        handleEditSubmit(form) {
            this.$refs[form].validate((valid) => {
                if (valid) {
                    if (this.operation) {
                        addAspectRatio(JSON.stringify(this.editForm)).then((res) => {
                            if (res.data == 1) {
                                this.$message.success({ message: this.$t('message.addSuccess') });
                                this.pageNum = 1;
                                this.setAspectRatioList();
                                this.editFormVisible = false;
                            } else {
                                this.$message.error({ message: this.$t('message.addFail') });
                            }
                        }).catch((res) => {
                            this.$message.warning({ message: res });
                        });
                    } else {
                        updateAspectRatio(JSON.stringify(this.editForm)).then((res) => {
                            if (res.data == 1) {
                                this.$message.success({ message: this.$t('message.updateSuccess') });
                                this.setAspectRatioList();
                                this.editFormVisible = false;
                            } else {
                                this.$message.error({ message: this.$t('message.updateFail') });
                            }
                        }).catch((res) => {
                            this.$message.warning({ message: res });
                        });
                    }

                } else {
                    //console.log('error submit!!');
                }
            });
        }
    }
}
</script>


<style lang="scss" scoped>
@import "~@/styles/admin-common.scss";
</style>