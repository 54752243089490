import request from '@/http/request'

/******************* secondCategory ***********************/
// 根据id获取某个二级分类信息
export function getSecondCategoryInfo (data) {
    return request({
        url: '/second-category/info',
        method: 'post',
        data
    })
}

// 分页获取二级分类信息
export function getSecondCategoryByPage (data) {
    return request({
        url: '/second-category/get-by-page',
        method: 'post',
        data
    })
}

// 添加
export function addSecondCategory (data) {
    return request({
        url: '/second-category/add',
        method: 'post',
        data
    })
}

// 修改
export function updateSecondCategory (data) {
    return request({
        url: '/second-category/update',
        method: 'post',
        data
    })
}

// 删除
export function deleteSecondCategory (data) {
    return request({
        url: '/second-category/delete',
        method: 'post',
        data
    })
}

// 获取所有二级分类信息
export function getSecondCategoryList () {
    return request({
        url: '/second-category/list',
        method: 'get'
    })
}

// 根据一级分类id获取二级分类信息
export function getSecondCategoryByFcId(data){
    return request({
        url: '/second-category/get-by-fc-id',
        method: 'post',
        data
    })
}

// 根据一级分类id获取二级分类信息
export function getSecondCategoryByFcIdAll(data){
    return request({
        url: '/second-category/get-by-fc-id-all',
        method: 'post',
        data
    })
}

export function validateNameAndFcId(data){
    return request({
        url: '/second-category/validate',
        method: 'post',
        data
    })
}