import request from '@/http/request'


/**************************** Size **************************** */
// 获取设备尺寸作为筛选条件
export function getSizeList () {
    return request({
        url: '/size/list',
        method: 'get',
    })
}

export function getSizeByPage (data) {
    return request({
        url: '/size/get-by-page',
        method: 'post',
        data
    })
}

export function addSize (data) {
    return request({
        url: '/size/add',
        method: 'post',
        data
    })
}

export function updateSize (data) {
    return request({
        url: '/size/update',
        method: 'post',
        data
    })
}

export function deleteSize (data) {
    return request({
        url: '/size/delete',
        method: 'post',
        data
    })
}

export function validateSizeExist(data){
    return request({
        url: '/size/validate',
        method: 'post',
        data
    })
}