import request from '@/http/request'

// 用户登录
export function login(data) {
  return request({
    url: '/login',
    method: 'post',
    data
  })
}

//用户登出
export function logout() {
  return request({
    url: '/logout',
    method: 'post'
  })
}


//获取分页用户信息
export function getUserByPage(data) {
  return request({
    url: '/user/get-by-page',
    method: 'post',
    data
  })
}

//验证该用户名是否存在
export function validateUsernameExist(data) {
  return request({
    url: '/user/validate-username-exist',
    method: 'post',
    data
  })
}

//添加用户信息
export function addUser(data) {
  return request({
    url: '/user/add',
    method: 'post',
    data
  })
}

//修改用户信息
export function updateUser(data) {
  return request({
    url: '/user/update',
    method: 'post',
    data
  })
}

//删除用户信息
export function deleteUser(data) {
  return request({
    url: '/user/delete',
    method: 'post',
    data
  })
}

//验证密码是否正确
export function validatePasswordCorrect(data) {
  return request({
    url: '/user/validate-password-correct',
    method: 'post',
    data
  })
}

//删除用户信息
export function updatePassword(data) {
  return request({
    url: '/user/update-password',
    method: 'post',
    data
  })
}