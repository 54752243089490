import request from '@/http/request'

export function addMessage(data) {
    return request({
        url: '/message/add',
        method: 'post',
        data
    })
}

export function deleteMessage(data) {
    return request({
        url: '/message/delete',
        method: 'post',
        data
    })
}

//获取分页消息
export function getMessageByPage(data) {
    return request({
        url: '/message/get-by-page',
        method: 'post',
        data
    })
}

//获取总数
export function getMessageCount() {
    return request({
        url: '/message/total',
        method: 'get',
    })
}