import request from '@/http/request'

/******************* threeCategory ***********************/
// 分页获取三级分类信息
export function getThreeCategoryByPage (data) {
    return request({
        url: '/three-category/get-by-page',
        method: 'post',
        data
    })
}

// 添加
export function addThreeCategory (data) {
    return request({
        url: '/three-category/add',
        method: 'post',
        data
    })
}

// 修改
export function updateThreeCategory (data) {
    return request({
        url: '/three-category/update',
        method: 'post',
        data
    })
}

// 删除
export function deleteThreeCategory (data) {
    return request({
        url: '/three-category/delete',
        method: 'post',
        data
    })
}

// 根据一级分类id获取二级分类信息
export function getThreeCategoryByScId(data){
    return request({
        url: '/three-category/get-by-sc-id',
        method: 'post',
        data
    })
}

export function validateNameAndScId(data){
    return request({
        url: '/three-category/validate',
        method: 'post',
        data
    })
}

