export default {
    state: {
        appName: "ZUNIDATA",  // 应用名称
        isCollapse: false,  // 导航栏收缩状态
    },
    getters: {
        collapse(state){// 获取导航栏收缩状态，对应着上面state
            return state.isCollapse;
        }
    },
    mutations: {
        collapse(state){  // 改变收缩状态
            state.isCollapse = !state.isCollapse;
        }
    },
    actions: {

    }
}