import Vue from 'vue'
import VueI18n from 'vue-i18n'

import locale from 'element-ui/lib/locale';
import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
import enLocale from 'element-ui/lib/locale/lang/en';

import zh_cn from './languages/zh_cn.json'
import en_us from './languages/en_us.json'

Vue.use(VueI18n)

const message = {
  'zh_cn': {
    ...zh_cn,
    ...zhLocale
  },
  'en_us': {
    ...en_us,
    ...enLocale
  }
}
 
// 注册i18n实例并引入语言文件，文件格式等下解析
const i18n = new VueI18n({
  locale: 'en_us',
  messages: message
})

//为了实现element插件的多语言切换
locale.i18n((key, value) => i18n.t(key, value))

// 暴露i18n
export default i18n