import request from '@/http/request'


/**************************** Resolution **************************** */
// 获取屏幕分辨率作为筛选条件
export function getResolutionList () {
    return request({
        url: '/resolution/list',
        method: 'get',
    })
}

export function getResolutionByPage (data) {
    return request({
        url: '/resolution/get-by-page',
        method: 'post',
        data
    })
}

export function addResolution (data) {
    return request({
        url: '/resolution/add',
        method: 'post',
        data
    })
}

export function updateResolution (data) {
    return request({
        url: '/resolution/update',
        method: 'post',
        data
    })
}

export function deleteResolution (data) {
    return request({
        url: '/resolution/delete',
        method: 'post',
        data
    })
}

export function validateResolutionExist(data){
    return request({
        url: '/resolution/validate',
        method: 'post',
        data
    })
}