<template>
    <div class="menu-bar-container" :class="isCollapse ? 'menu-bar-collapse-width' : 'menu-bar-width'">
        <!-- logo -->
        <div class="logo">
            <!-- <el-image :src="this.logo"></el-image> -->
            <img :src="logoUrl" :class="isCollapse ? 'img-hide' : 'img-show'" />
        </div>
        <!-- 导航菜单 -->
        <el-menu :default-active="defaultActive" :class="isCollapse ? 'menu-bar-collapse-width' : 'menu-bar-width'"
            @open="handleOpen" @close="handleClose" @select="handleSelect" :collapse="isCollapse"
            active-text-color="#ffd04b" :router="true" :unique-opened="true">

            <el-menu-item index="/admin/home">
                <fa-icon :icon="['fas', 'home']" />
                <span slot="title">{{ $t("menu.home") }}</span>
            </el-menu-item>


            <el-submenu index="#product">
                <template slot="title">
                    <fa-icon :icon="['fas', 'laptop']" />
                    <span slot="title">{{ $t("menu.productManage") }}</span>
                </template>
                <el-menu-item index="/admin/networking" v-if="loginRole != 'advertising admin'">
                    <fa-icon :icon="['fas', 'hdd']" />{{ $t("menu.networkingManage") }}
                </el-menu-item>

                <el-submenu index="#advertising" v-if="loginRole != 'networking admin'">
                    <template slot="title">
                        <fa-icon :icon="['fas', 'tv']" />
                        <span slot="title">{{ $t("menu.advertisingManage") }}</span>
                    </template>
                    <el-menu-item index="/admin/advertising">
                        <fa-icon :icon="['fas', 'tv']" />{{ $t("menu.advertisingManage") }}
                    </el-menu-item>
                    <el-menu-item index="/admin/advertising/cpu">
                        <fa-icon :icon="['fas', 'microchip']" />{{ $t("menu.cpuManage") }}
                    </el-menu-item>
                    <el-menu-item index="/admin/advertising/size">
                        <fa-icon :icon="['fas', 'cube']" />{{ $t("menu.sizeManage") }}
                    </el-menu-item>
                    <el-menu-item index="/admin/advertising/aspectRatio">
                        <fa-icon :icon="['fas', 'retweet']" />{{ $t("menu.aspectRatioManage") }}
                    </el-menu-item>
                    <el-menu-item index="/admin/advertising/resolution">
                        <fa-icon :icon="['far', 'object-ungroup']" />{{ $t("menu.resolutionManage") }}
                    </el-menu-item>
                </el-submenu>
            </el-submenu>

            <el-submenu index="#category">
                <template slot="title">
                    <fa-icon :icon="['fas', 'sitemap']" />
                    <span slot="title">{{ $t("menu.categoryManage") }}</span>
                </template>
                <el-menu-item index="/admin/category/firstCategory">
                    <fa-icon :icon="['fas', 'square']" />{{ $t("menu.firstCategoryManage") }}
                </el-menu-item>
                <el-menu-item index="/admin/category/secondCategory">
                    <fa-icon :icon="['fas', 'th-large']" />{{ $t("menu.secondCategoryManage") }}
                </el-menu-item>
                <el-menu-item index="/admin/category/threeCategory">
                    <fa-icon :icon="['fas', 'th']" />{{ $t("menu.threeCategoryManage") }}
                </el-menu-item>
            </el-submenu>

            <el-submenu index="#file">
                <template slot="title">
                    <fa-icon :icon="['fas', 'folder-open']" />
                    <span slot="title">{{ $t("menu.fileManage") }}</span>
                </template>
                <el-menu-item index="/admin/file/imageBanner">
                    <fa-icon :icon="['fas', 'image']" />{{ $t("menu.imageBannerManage") }}
                </el-menu-item>
                <el-menu-item index="/admin/file/videoBanner">
                    <fa-icon :icon="['fas', 'file-video']" />{{ $t("menu.videoBannerManage") }}
                </el-menu-item>
                <el-menu-item index="/admin/file/supportFile">
                    <fa-icon :icon="['fas', 'folder']" />
                    <span slot="title">{{ $t("menu.supportFileManage") }}</span>
                </el-menu-item>
            </el-submenu>

            <el-menu-item index="/admin/message">
                <fa-icon :icon="['fas', 'paper-plane']" />
                <span slot="title">{{ $t("menu.messageManage") }}</span>
            </el-menu-item>

            <!-- <el-menu-item index="/admin/email">
                <fa-icon :icon="['fas', 'envelope']" />
                <span slot="title">{{ $t("menu.emailManage") }}</span>
            </el-menu-item> -->

            <el-menu-item index="/admin/record" v-if="loginRole == 'super admin'">
                <fa-icon :icon="['fas', 'file-alt']" />
                <span slot="title">{{ $t("menu.recordManage") }}</span>
            </el-menu-item>

            <el-menu-item index="/admin/user" v-if="loginRole == 'super admin'">
                <fa-icon :icon="['fas', 'user']" />
                <span slot="title">{{ $t("menu.userManage") }}</span>
            </el-menu-item>

            <el-menu-item index="/admin/swagger" v-if="loginRole == 'super admin'">
                <fa-icon :icon="['fas', 'paperclip']" />
                <span slot="title">{{ $t("menu.swaggerManage") }}</span>
            </el-menu-item>

            <el-menu-item index="/">
                <fa-icon :icon="['fas', 'link']" />
                <span slot="title">{{ $t("menu.webPage") }}</span>
            </el-menu-item>


        </el-menu>
    </div>
</template>

<script>


import { mapState } from 'vuex'
export default {
    data() {
        return {
            defaultActive: '',
            logoUrl: "",
            loginRole: '',
        };
    },
    computed: {
        ...mapState({
            isCollapse: state => state.app.isCollapse,
        })
    },
    methods: {
        getSession() {
            this.loginRole = this.$cookies.get("role");
        },
        handleOpen() {
            //console.log('handleOpen');
        },
        handleClose() {
            //console.log('handleClose');
        },
        handleSelect(a, b) {
            //console.log('handleSelect');
        }
    },
    mounted() {
        this.getSession();
        this.defaultActive = this.$route.path;
        this.logoUrl = require("@/assets/logo.png");
    },
    watch: {
        $route(to, from) {
            this.defaultActive = to.path;
        },
    },
};
</script>

<style scoped lang="scss">
@import "~@/styles/variables.scss";

.menu-bar-container {
    position: fixed;
    top: 0px;
    left: 0;
    bottom: 0;

    .el-menu {
        position: absolute;
        top: 60px;
        bottom: 0px;
        text-align: left;
        background: $menuBg;

        svg {
            font-size: 18px !important;
            width: 18px;
            margin-right: 15px;
            padding-left: 0px;
        }

        .el-submenu__tittle {
            background: $menuBg;
            color: $menuText;

            :hover {
                background-color: $menuHover;
            }
        }

        .el-menu-item {
            background: $menuBg;
            color: $menuText;
        }

        .el-menu-item:hover {
            background-color: $menuHover;
        }

        .el-menu-item.is-active {
            background-color: $menuHover;
        }
    }

    .logo {
        position: absolute;
        height: 60px;
        line-height: 60px;
        text-align: center;
        background: #222222;
        width: 220px;

        img {
            margin: 10px 0px 0px 40px;
        }
    }

    .menu-bar-width {
        width: 220px !important;
        transition: width .3s;

    }

    .menu-bar-collapse-width {
        width: 60px !important;
        transition: width .3s;
    }

    .img-hide {
        display: none;
    }

    .img-show {
        display: block;
    }

}
</style>
s

<style lang="scss">
@import "~@/styles/variables.scss";

.menu-bar-container .el-menu .el-submenu .el-submenu__title {
    background: $menuBg !important;
    color: $menuText !important;
}

.menu-bar-container .el-menu .el-submenu .el-submenu__title:hover {
    background-color: $menuHover !important;
}
</style>