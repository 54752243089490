import Vue from 'vue'
import VueRouter from 'vue-router'

// admin page url
import Layout from '@/views/admin/layout'
import Home from '@/views/admin/home'
import User from '@/views/admin/user'
import Networking from '@/views/admin/networking'
import Advertising from '@/views/admin/advertising'
import Cpu from '@/views/admin/advertising/cpu'
import Size from '@/views/admin/advertising/size'
import Resolution from '@/views/admin/advertising/resolution'
import AspectRatio from '@/views/admin/advertising/aspectRatio'
import FirstCategory from '@/views/admin/category/firstCategory'
import SecondCategory from '@/views/admin/category/secondCategory'
import ThreeCategory from '@/views/admin/category/threeCategory'
import Message from '@/views/admin/message'
import Record from '@/views/admin/record'
import Swagger from '@/views/admin/swagger'
import SupportFile from '@/views/admin/file/supportFile'
import ImageBanner from '@/views/admin/file/imageBanner'
import VideoBanner from '@/views/admin/file/videoBanner'
import Email from '@/views/admin/email'



const originalPush = VueRouter.prototype.push

VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch(err => err)
}


Vue.use(VueRouter)

const routes = [


    /*  admin page  */
    {
        path: '/admin',
        name: 'home',
        component: Layout,
        redirect: '/admin/home',
        children: [
            { path: '/admin/home', component: Home },
            { path: '/admin/user', component: User },
            { path: '/admin/networking', component: Networking },
            { path: '/admin/advertising', component: Advertising },
            { path: '/admin/advertising/cpu', component: Cpu },
            { path: '/admin/advertising/size', component: Size },
            { path: '/admin/advertising/aspectRatio', component: AspectRatio },
            { path: '/admin/advertising/resolution', component: Resolution },
            { path: '/admin/category/firstCategory', component: FirstCategory },
            { path: '/admin/category/secondCategory', component: SecondCategory },
            { path: '/admin/category/threeCategory', component: ThreeCategory },
            { path: '/admin/file/imageBanner', component: ImageBanner },
            { path: '/admin/file/videoBanner', component: VideoBanner },
            { path: '/admin/file/supportFile', component: SupportFile },
            { path: '/admin/message', component: Message },
            { path: '/admin/email', component: Email },
            { path: '/admin/record', component: Record },
            { path: '/admin/swagger', component: Swagger },
        ]
    },


    /*  login page  */
    {
        path: '/login',
        name: 'login',
        component: () => import('../views/login/index.vue')
    },


    /*  error-page  */
    {
        path: '/401',
        name: '401',
        component: () => import('../views/error-page/401.vue')
    },
    {
        path: '/404',
        name: '404',
        component: () => import('../views/error-page/404.vue')
    },


    /*  web-page  */
    {
        path: '/',
        name: '/',
        component: () => import('../views/web/home/index.vue')
    },

    {
        path: '/home',
        name: 'web-home',
        redirect: '/',
        component: () => import('../views/web/home/index.vue')
    },

    {
        path: '/about',
        name: 'web-about',
        component: () => import('../views/web/about/index.vue')
    },

    {
        path: '/contact',
        name: 'web-contact',
        component: () => import('../views/web/contact/index.vue')
    },

    {
        path: '/support',
        name: 'web-support',
        component: () => import('../views/web/support/index.vue')
    },

    {
        path: '/advertising/*',
        name: 'web-advertising',
        component: () => import('../views/web/advertisingList/index.vue'),
    },

    {
        path: '/networking/*',
        name: 'web-networking',
        component: () => import('../views/web/networkingList/index.vue'),
    },

    {
        path: '/advertising-detail',
        name: 'web-advertising-detail',
        component: () => import('../views/web/advertisingDetail/index.vue')
    },

    {
        path: '/networking-detail',
        name: 'web-networking-detail',
        component: () => import('../views/web/networkingDetail/index.vue')
    },

    { path: '*', redirect: '/404' }
]



const router = new VueRouter({
    routes
})

import Cookies from "@/utils/cookie"
router.beforeEach((to, from, next) => {
    // 登录界面登录成功后会把用户信息保存
    // 存在时间为会话生命周期，页面关闭即失效
    let user = Cookies.get('username');
    if (to.path == '/login') {
        // 如果是访问登录界面，会话信息存在，表示已经登录过，直接跳转到主页
        if (user) {
            next({ path: '/admin/home' })
        } else {
            next()
        }
        //next()
    } else {
        // 如果是访问其他界面，且会话信息不存在，跳转到登录界面
        if (to.path.includes("/admin")) {
            if (!user) {
                next({ path: '/login' })
            } else {
                next()
            }
        } else {
            next()
        }
    }
})


export default router
