<template>
    <div id="app">

        <!-- 数据表格 -->
        <el-table :data="firstCategoryList" border :stripe="true" style="width: 100%" max-height="590"
            highlight-selection-row highlight-current-row>
            <el-table-column type="index" label="#" min-width="20%">
            </el-table-column>
            <el-table-column prop="name" :label="$t('table.name')" min-width="40%" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="createTime" :label="$t('table.createTime')" min-width="40%" :show-overflow-tooltip="true">
            </el-table-column>
        </el-table>

    </div>
</template>

<script>
import { getFirstCategoryList } from "@/api/category/firstCategory"

export default {
    name: 'admin-firstCategory',
    data() {
        return {
            firstCategoryList: [],
        }
    },
    mounted() {
        this.setFirstCategoryList();
    },
    methods: {
        setFirstCategoryList() {
            getFirstCategoryList().then((res) => {
                this.firstCategoryList = res.data;
            }).catch((res) => {});
        },
    }
}
</script>


<style lang="scss" scoped>
@import "~@/styles/admin-common.scss";
</style>