<template>
    <div id="app">
        <iframe :src=src class="m-iframe" />
    </div>
</template>

<script>
import { baseUrl } from '@/utils/global'

export default {
    name: 'admin-record',
    data(){
        return{
            src:""
        }
    },
    mounted(){
        this.initSrc()
    },
    methods:{
        initSrc(){
            this.src = baseUrl.substring(0,baseUrl.lastIndexOf("/")+1) + "swagger-ui.html";
        }
    }
}
</script>


<style lang="scss" scoped>
@import "~@/styles/admin-common.scss";

.m-iframe {
    width: 100%;
    height: 80vh;
}
</style>