import request from '@/http/request'



/****************************** Advertising **************************** */
// 获取二级分类（id）下,多条件过滤下(cpu，size，resolution，aspectRatio)的所有广告机信息
export function getAdvertisingFilter (data) {
    return request({
        url: '/advertising/filter',
        method: 'post',
        data
    })
}


export function getAdvertisingFilterCondition (data) {
    return request({
        url: '/advertising/filter-condition',
        method: 'post',
        data
    })
}

// 根据id获取单条广告机信息
export function getAdvertisingInfo (data) {
    return request({
        url: '/advertising/info',
        method: 'post',
        data
    })
}

export function getAdvertisingByPage (data) {
    return request({
        url: '/advertising/get-by-page',
        method: 'post',
        data
    })
}

export function addAdvertising (data) {
    return request({
        url: '/advertising/add',
        method: 'post',
        data
    })
}

export function deleteAdvertising (data) {
    return request({
        url: '/advertising/delete',
        method: 'post',
        data
    })
}

export function updateAdvertising (data) {
    return request({
        url: '/advertising/update',
        method: 'post',
        data
    })
}

// 获取广告机数据总量
export function getAdvertisingCount () {
    return request({
        url: '/advertising/total',
        method: 'get',
    })
}

// 获取各二级分类下的广告机数据量
export function getAdvertisingPieChart () {
    return request({
        url: '/advertising/pie-chart',
        method: 'get',
    })
}

export function validateNameExist (data) {
    return request({
        url: '/advertising/validate',
        method: 'post',
        data
    })
}

export function getAdvertisingDetail (data) {
    return request({
        url: '/advertising/detail',
        method: 'post',
        data
    })
}

export function updateAdvertisingCover(data){
    return request({
        url: '/advertising/update-cover',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data
    })
}

export function addAdvertisingExhibit(data){
    return request({
        url: '/advertising/add-exhibit',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data
    })
}

export function deleteAdvertisingExhibit(data){
    return request({
        url: '/advertising/delete-exhibit',
        method: 'post',
        data
    })
}

export function updateAdvertisingExhibit(data){
    return request({
        url: '/advertising/update-exhibit',
        method: 'post',
        data
    })
}




