<template>
    <div id="app">
        <el-container>
            <el-header direction="vertical" v-show="showThis()" class="m-header">
                <div class="logo">
                    <el-image :src=logo @click="handleClickHome" style="cursor: pointer;" />
                </div>
                <el-menu :default-active="defaultActive" class="m-menu-show" mode="horizontal" :router="true"
                    menu-trigger="click" @select="handleSelect" :unique-opened="true">
                    <el-menu-item index="/">{{ $t("navigation.home") }}</el-menu-item>

                    <el-submenu index="/product">
                        <template slot="title">{{ $t("navigation.product") }}</template>
                        <el-submenu :key="item.name" :index="'/' + item.name" v-for="item in categoryList">
                            <template slot="title">{{ item.name }}</template>
                            <el-menu-item :key="child.name"
                                :index="'/' + item.name + '/' + child.name + '?id=' + child.id"
                                v-for="child in item.secondCategoryList">
                                {{ child.name }}
                            </el-menu-item>
                        </el-submenu>
                    </el-submenu>

                    <el-menu-item index="/contact">{{ $t("navigation.contact") }}</el-menu-item>
                    <el-menu-item index="/support">{{ $t("navigation.support") }}</el-menu-item>
                    <el-menu-item index="/about">{{ $t("navigation.about") }}</el-menu-item>
                    <!-- 语言选择器 -->
                    <!-- <LangSelector class="lang-selector"></LangSelector> -->

                    <i class="el-icon-search m-search" @click="handleShowSearchDialog"></i>
                </el-menu>

                <div class="m-menu-bar" @click="changeMenu()">
                    <fa-icon :icon="['fas', 'bars']" />
                </div>

                <!-- 小屏导航栏 -->
                <el-drawer :title="$t('navigation.title')" :visible.sync="drawer" :direction="direction"
                    :before-close="handleCloseDrawer" size="70%">
                    <el-menu :default-active="defaultActive" mode="vertical" :router="true" menu-trigger="click"
                        @select="handleSelect" :unique-opened="true">
                        <el-menu-item index="/">{{ $t("navigation.home") }}</el-menu-item>

                        <el-submenu index="/product">
                            <template slot="title">{{ $t("navigation.product") }}</template>
                            <el-submenu :key="item.name" :index="'/' + item.name" v-for="item in categoryList">
                                <template slot="title">{{ item.name }}</template>
                                <el-menu-item :key="child.name"
                                    :index="'/' + item.name + '/' + child.name + '?id=' + child.id"
                                    v-for="child in item.secondCategoryList">
                                    {{ child.name }}
                                </el-menu-item>
                            </el-submenu>
                        </el-submenu>

                        <el-menu-item index="/contact">{{ $t("navigation.contact") }}</el-menu-item>
                        <el-menu-item index="/support">{{ $t("navigation.support") }}</el-menu-item>
                        <el-menu-item index="/about">{{ $t("navigation.about") }}</el-menu-item>
                        <!-- 语言选择器 -->
                        <!-- <LangSelector class="lang-selector"></LangSelector> -->

                        <i class="el-icon-search m-search" @click="handleShowSearchDialog"></i>
                    </el-menu>
                </el-drawer>

                <el-dialog class="search-list" :title="$t('common.search2')" :visible.sync="searchDialogVisible"
                    :destroy-on-close="true" :width="searchDialogWidth">

                    <el-input :placeholder="$t('common.searchKeyword')" v-model="queryForm.keyword" clearable
                        @keyup.enter.native="handleSearch" auto-complete="off">
                        <el-select v-model="queryForm.fcId" slot="prepend" :placeholder="$t('common.pleaseSelect')">
                            <el-option label="Networking" value="1" selected></el-option>
                            <el-option label="Advertising" value="2"></el-option>
                        </el-select>
                        <el-button slot="append" icon="el-icon-search" @click="handleSearch"></el-button>
                    </el-input>
                    <el-divider><fa-icon :icon="['far', 'bookmark']" /></el-divider>

                    <div class="search-content">
                        <el-button type="primary" plain @click="handleClickJump(item.id)" v-for="item in dataList"
                            :key="item.id">{{
                                item.name
                            }}</el-button>
                    </div>

                    <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
                        :current-page="pageNum" :page-size="pageSize" layout="prev, pager, next" :total="total">
                    </el-pagination>

                </el-dialog>

            </el-header>
            <el-main>
                <router-view />
            </el-main>
            <el-footer v-show="showThis()">
                <div class="footer">
                    <p>Copyright © 2022 ZUNIDATA Inc. All rights reserved.</p>
                </div>
            </el-footer>
        </el-container>
    </div>
</template>

<script>
import LangSelector from "@/components/LangSelector"
import { getFirstSecondCategoryList } from "@/api/category/firstCategory"
import { getGlobalSearch } from "@/api/common"

export default {
    name: "App",
    components: {
        LangSelector,
    },
    data() {
        return {
            logo: '',
            defaultActive: '',
            path: "",
            categoryList: [],
            searchDialogVisible: false,
            queryForm: {
                keyword: "",
                fcId: "1",
            },
            dataList: [],
            pageNum: 1,
            pageSize: 5,
            total: 0,
            drawer: false,
            direction: 'ltr',
            searchDialogWidth: '50%',
            screenWidth: window.innerWidth,
        }
    },
    mounted() {
        window.onresize = () => {
            return (() => {
                this.screenWidth = window.innerWidth;
                this.setWidth();
            })();
        };
        this.logo = require("@/assets/logo.png");
        this.setCategoryList();
    },
    methods: {
        // 需要显示该组件的页面
        showThis() {
            return (this.path === '/'
                || this.path === '/home'
                || this.path === '/about'
                || this.path === '/product'
                || this.path === '/contact'
                || this.path === '/support'
                || this.path === '/advertising-detail'
                || this.path === '/networking-detail'
                || this.path.startsWith("/Advertising")
                || this.path.startsWith("/Networking"))
        },
        handleSelect(key, keyPath) {
            //console.log(key);
        },
        setCategoryList() {
            getFirstSecondCategoryList().then((res) => {
                this.categoryList = res.data;
            }).catch(function (res) { })
        },
        handleShowSearchDialog() {
            this.searchDialogVisible = true;
        },
        handleSearch() {
            let params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                queryForm: this.queryForm
            };
            getGlobalSearch(params).then(res => {
                this.dataList = res.data.content
                this.total = res.data.totalSize;
            })
        },
        handleClickJump(id) {
            if (this.queryForm.fcId == "1") {
                this.$router.push('/networking-detail?id=' + id)
            } else if (this.queryForm.fcId == "2") {
                this.$router.push('/advertising-detail?id=' + id)
            }
            this.searchDialogVisible = false;
        },
        handleSizeChange(val) {
            if (this.pageSize != val) {
                this.pageSize = val;
                this.pageNum = 1;
                this.handleSearch();
            }
        },
        handleCurrentChange(val) {
            this.pageNum = val;
            this.handleSearch();
        },
        handleClickHome() {
            this.$router.push('/');
        },
        changeMenu() {
            this.drawer = true;
        },
        handleCloseDrawer(done) {
            done();
        },
        setWidth() {
            if (this.screenWidth <= 800) {
                this.searchDialogWidth = '100%'
            } else {
                this.searchDialogWidth = '50%'
            }
        }
    },
    watch: {
        $route(to, from) {
            this.path = to.path;
            if (to.path.startsWith("/Advertising")
                || to.path.startsWith("/Networking")
                || to.path === '/advertising-detail'
                || to.path === '/networking-detail') {
                this.defaultActive = "/product"
            } else {
                this.defaultActive = to.path;
            }
        },
    },
}
</script>


<style lang="scss" scoped>
#app {
    font-family: "Avenir", Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #606266;
}

.el-header {
    display: flex;
    justify-content: space-between;
    align-content: center;
    margin: 0 auto;
    font-weight: bolder;
    background-color: #fff;
    overflow: hidden;

    .logo {
        width: 180px;
        padding: 10px;

        img {
            width: 160px;
            height: 50px;
            line-height: 60px;
        }
    }

    .m-menu-bar {
        padding: 0px 20px;
        text-align: center;
        height: 60px;
        line-height: 60px;
        cursor: pointer;
        color: #909399;
        float: right;
        display: none;
    }

    .m-menu-show {
        visibility: visible;
    }

    .lang-selector,
    .m-search {
        height: 60px;
        line-height: 60px;
        padding: 0px 20px;
        color: #909399;
        cursor: pointer;

        :hover {
            color: #303133;
        }
    }

    .search-list {

        .el-select {
            width: 120px;
        }

        .search-content {
            .el-button {
                width: 100%;
                margin-left: 0px;
                margin-bottom: 10px;
            }
        }

        .el-pagination {
            margin-top: 20px;
            margin-left: 0px;
        }
    }
}

.el-main {
    padding: 0 !important;
}

.el-footer {
    .footer {
        height: 100px;
        line-height: 100px;
        text-align: center;
        margin-top: 100px;

        p {
            height: 20px;
            line-height: 20px;
        }
    }
}
</style>

<style lang="scss">
.el-submenu__title:hover {
    background-color: #fff !important;
    color: #303133 !important;
}
</style>


<style lang="scss" scoped>
@media screen and (max-width: 800px) {
    .m-menu-bar {
        display: block !important;
    }

    .m-menu-show {
        //visibility: hidden !important;
        display: none !important;
    }
}
</style>