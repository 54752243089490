<template>
  <el-dropdown class="lang-selector" @command="handleCommand">
    <span class="el-dropdown-link">
      <span id="language">{{ $t("navigation.language") }}</span>
      <!-- <i class="el-icon-arrow-down el-icon--right"></i> -->
      <!-- <svg-icon icon-class="international" /> -->
    </span>
    <el-dropdown-menu slot="dropdown" placement="bottom-start">
      <el-dropdown-item command="en_us">English</el-dropdown-item>
      <el-dropdown-item command="zh_cn">中文</el-dropdown-item>
    </el-dropdown-menu>
  </el-dropdown>
</template>

<script>

  export default {
    methods: {
      // 语言切换
      handleCommand(command) {
        let lang = command
        this.$i18n.locale = lang
      }
    }
  }
</script>