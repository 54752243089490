<template>
    <div id="app">

        <!-- 查询表单 -->
        <el-form :inline="true" :model="queryForm" class="demo-form-inline">
            <el-form-item label="">
                <el-input v-model="queryForm.name" @blur="queryForm.name = $event.target.value.trim()"
                    :placeholder="$t('table.name')" auto-complete="off" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleQuery()">{{ $t('table.search') }}</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleAdd()" v-if="permiss">{{ $t('table.add') }}</el-button>
            </el-form-item>
        </el-form>

        <!-- 数据表格 -->
        <el-table :data="imageList" border :stripe="true" style="width: 100%" max-height="650"
            @selection-change="handleSelectionChange" highlight-selection-row highlight-current-row>

            <el-table-column type="selection" min-width="5%">
            </el-table-column>
            <el-table-column type="index" label="#" :index="handleIndex" min-width="5%">
            </el-table-column>
            <el-table-column prop="name" :label="$t('table.name')" min-width="10%" :show-overflow-tooltip="true">
            </el-table-column>
            <!-- <el-table-column prop="url" :label="$t('table.url')" min-width="15%" :show-overflow-tooltip="true">
            </el-table-column> -->
            <el-table-column prop="resolution" :label="$t('table.resolution')" min-width="10%"
                :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="size" :label="$t('table.sizeKB')" min-width="10%" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="description" :label="$t('table.description')" min-width="15%"
                :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="createTime" :label="$t('table.create_time')" min-width="15%"
                :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="updateTime" :label="$t('table.update_time')" min-width="15%"
                :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column :label="$t('table.operation')" min-width="15%">
                <template slot-scope="scope">
                    <el-button size="mini" type="primary" @click="handlePrevier(scope.$index, scope.row)">{{
                        $t('table.preview')
                        }}</el-button>
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)" v-if="permiss">{{ $t('table.edit')
                        }}</el-button>
                    <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)" v-if="permiss">{{
                        $t('table.delete') }}</el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- 工具栏 -->
        <div class="toolbar">
            <el-button type="danger" size="small" @click="handleDeletes()" v-if="permiss">{{ $t('table.deletes')
                }}</el-button>
            <!-- 分页列表 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
                :current-page="pageNum" :page-sizes="[10, 20, 50]" :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>

        <!-- 添加/编辑表单 -->
        <el-dialog :title="operation ? $t('table.add') : $t('table.edit')" :visible.sync="editFormVisible"
            @closed="handleClose()" :destroy-on-close="true">
            <el-form ref="editForm" :model="editForm" :rules="editRules" label-position="right" label-width="100px">
                <el-form-item :label="$t('table.name')" prop="name">
                    <el-input v-model="editForm.name" @blur="editForm.name = $event.target.value.trim()"
                        auto-complete="off" maxlength="20"></el-input>
                </el-form-item>

                <el-form-item :label="$t('table.description')" prop="description">
                    <el-input type="textarea" autosize v-model="editForm.description"
                        @blur="editForm.description = $event.target.value.trim()" auto-complete="off" maxlength="100"
                        show-word-limit></el-input>
                </el-form-item>

                <el-form-item :label="$t('table.image')" prop="file">
                    <el-upload action="#" list-type="picture-card" :auto-upload="false" :limit="1"
                        :on-change="handleUploadChange" :on-remove="handleUploadRemove" :on-preview="handleEditPreview"
                        ref="uploadImg" :http-request="handleUpload" accept="image/png,image/jpg,image/jpeg"
                        :before-upload="handleBeforeUpload">
                        <i class="el-icon-plus"></i>
                        <div slot="tip" class="el-upload__tip">{{ $t('rules.uploadImage') }}</div>
                    </el-upload>
                </el-form-item>

                <el-form-item :label="$t('table.sizeKB')" prop="size">
                    <el-input v-model="editForm.size" auto-complete="off" maxlength="20" :disabled="true"></el-input>
                </el-form-item>

                <el-form-item :label="$t('table.resolution')" prop="resolution">
                    <el-input v-model="editForm.resolution" auto-complete="off" maxlength="20"
                        :disabled="true"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editFormVisible = false">{{ $t('table.cancel') }}</el-button>
                <el-button type="primary" @click="handleEditSubmit('editForm')">{{ $t('table.confirm') }}</el-button>
            </div>
        </el-dialog>

        <!-- 放大图 -->
        <el-image style="display: none;" ref="preview" :src="firstUrl" :preview-src-list="urlList"></el-image>

    </div>
</template>

<script>
import {
    getImageBannerByPage, deleteImageBanner, addImageBanner, updateImageBanner, validateNameExist, updateImageBannerFile
} from "@/api/file/imageBanner"
export default {
    name: 'admin-imageBanner',
    computed: {
        editRules() {
            const validateName = (rule, value, callback) => {
                if (this.oldName != value) {
                    if (value.length > 20) {
                        callback(new Error(this.$t('rules.lengthRange20')))
                    } else {
                        let param = {
                            name: value
                        }
                        validateNameExist(param).then(res => {
                            if (!res.data) {
                                callback(new Error(this.$t('rules.nameExist')))
                            } else {
                                callback()
                            }
                        }).catch(function (res) {
                            callback(new Error(this.$t('rules.exception')))
                        });
                    }
                }
                else {
                    callback()
                }
            }
            return {
                name: [
                    { required: true, message: this.$t('rules.inputName'), trigger: 'blur' },
                    { validator: validateName, trigger: 'blur' }
                ],
                file: [
                    // { required: true, message: this.$t('rules.selectImage'), trigger: 'change' }
                ]
            }
        }
    },
    data() {

        return {
            loginRole: "",
            permiss: false,
            imageList: [],
            queryForm: {
                name: '',
            },
            pageNum: 1,
            total: 10,
            pageSize: 10,
            pages: 1,
            operation: true,
            editFormVisible: false,
            editForm: {
                id: '',
                name: '',
                description: '',
                file: '',
                size: '',
                resolution: '',
            },
            oldName: '',
            oldSize: '',
            oldResolution: '',
            deleteIds: [],
            urlList: [""],
            firstUrl: '',
            file: null,
        }
    },
    mounted() {
        this.getSession();
        this.setImageList();
    },
    methods: {
        getSession() {
            this.loginRole = this.$cookies.get("role");
            if (this.loginRole == "super admin" || this.loginRole == "advertising admin" || this.loginRole == "networking admin") {
                this.permiss = true;
            } else {
                this.permiss = false;
            }
        },
        handleIndex(index) {
            const pageNum = this.pageNum // 当前页码
            const pageSize = this.pageSize // 每页条数
            return index + 1 + (pageNum - 1) * pageSize
        },
        setImageList() {
            let params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                queryForm: this.queryForm
            };
            getImageBannerByPage(JSON.stringify(params)).then((res) => {
                this.imageList = res.data.content;
                this.total = res.data.totalSize;
            }).catch((res) => { });
        },

        delete(ids) {
            this.$confirm(this.$t('message.deleteConfirm'), this.$t('message.tip'), {
                type: "warning"
            }).then(() => {
                deleteImageBanner(ids).then((res) => {
                    if (res.data >= 1) {
                        this.$message.success({ message: this.$t('message.deleteSuccess') });
                        this.setImageList();
                    } else {
                        this.$message.error({ message: this.$t('message.deleteFail') });
                    }
                }).catch((res) => {
                    this.$message.warning({ message: res });
                });
            }).catch(() => {
                this.$message.info({ message: this.$t('message.deleteCancel') });
            });
        },
        handleDelete(index, row) {
            let deleteId = [row.id];
            this.delete(deleteId);
        },
        handleDeletes() {
            if (this.deleteIds.length == 0) {
                this.$message.info({ message: this.$t('message.deleteDataSelect') });
            } else {
                this.delete(this.deleteIds);
            }
        },
        handleSelectionChange(val) {
            this.deleteIds = [];
            val.forEach(item => {
                this.deleteIds.push(item.id);
            });
        },
        handleQuery() {
            this.pageNum = 1;
            this.setImageList();
        },
        initEditDialog(operation) {
            this.operation = operation;
            this.editFormVisible = true;
            this.$nextTick(() => {
                this.$refs.editForm.clearValidate();
            })
        },
        handleAdd() {
            this.disabled = false;
            this.initEditDialog(true)
        },
        handleSizeChange(val) {
            if (this.pageSize != val) {
                this.pageSize = val;
                this.pageNum = 1;
                this.setImageList();
            }
        },
        handleCurrentChange(val) {
            this.pageNum = val;
            this.setImageList();
        },
        handlePrevier(index, row) {
            this.urlList = []
            this.urlList.push(row.url);
            this.$refs.preview.clickHandler();
        },
        handleEditPreview(file) {
            this.urlList = []
            this.urlList.push(file.url);
            this.$refs.preview.clickHandler();
        },
        handleBeforeUpload(file) {  //手动上传该方法无用

        },
        handleUploadChange(file, fileList) {
            if (fileList.length !== 0) {
                return new Promise((resolve, reject) => {
                    let reader = new FileReader();
                    reader.onload = () => {
                        let img = new Image();
                        img.src = reader.result
                        img.onload = () => {
                            this.editForm.size = Math.round(file.size / 1024);
                            this.editForm.resolution = (img.width + ' x ' + img.height);
                            this.editForm.file = file;
                            this.file = file
                        }
                    };
                    reader.readAsDataURL(file.raw);
                });
            }
        },
        handleUploadRemove(file, fileList) {
            if (fileList.length === 0) {
                this.editForm.file = null
                this.editForm.size = ""
                this.editForm.resolution = ""
                this.file = null;
            }
            if (!this.operation) {
                this.editForm.size = this.oldSize;
                this.editForm.resolution = this.oldResolution;
            }
        },
        handleUpload(file) {

        },
        handleEdit(index, row) {
            this.editForm.id = row.id;
            this.editForm.name = row.name;
            this.editForm.description = row.description;
            this.editForm.size = row.size;
            this.editForm.resolution = row.resolution;
            this.oldName = row.name;
            this.oldSize = row.size;
            this.oldResolution = row.resolution;
            this.initEditDialog(false)
        },
        handleClose() {
            // 清空表单
            Object.keys(this.editForm).forEach(key => (this.editForm[key] = ''));
            this.oldName = "";
            this.oldSize = "";
            this.oldResolution = "";
            this.file = null;
            this.$refs.uploadImg.clearFiles()
        },

        handleEditSubmit(form) {
            this.$refs[form].validate((valid) => {
                if (valid) {
                    this.$refs.uploadImg.submit()

                    if (this.file) {
                        var fd = new FormData() //携带文件必须使用此对象
                        const isLt2M = this.file.size / 1024 / 1024 < 2
                        if (!isLt2M) {
                            this.$message.info({ message: this.$t('rules.uploadImageSize') });
                            return;
                        }
                        fd.append("file", this.file.raw); //把文件实体添加到表单对象
                        fd.append("name", this.editForm.name);
                        fd.append("description", this.editForm.description);
                        fd.append("size", this.editForm.size);
                        fd.append("resolution", this.editForm.resolution);

                        if (this.operation) {
                            addImageBanner(fd).then((res) => {
                                if (res.data == 1) {
                                    this.$message.success({ message: this.$t('message.addSuccess') });
                                    this.pageNum = 1;
                                    this.setImageList();
                                    this.editFormVisible = false;
                                } else {
                                    this.$message.error({ message: res.data });
                                }
                            }).catch((res) => {
                                this.$message.warning({ message: res });
                            });
                        } else {
                            fd.append("id", this.editForm.id);
                            updateImageBannerFile(fd).then((res) => {
                                if (res.data == 1) {
                                    this.$message.success({ message: this.$t('message.updateSuccess') });
                                    this.setImageList();
                                    this.editFormVisible = false;
                                } else {
                                    this.$message.error({ message: res.data });
                                }
                            }).catch((res) => {
                                this.$message.error({ message: res });
                            });
                        }
                    } else {
                        if (this.operation) {
                            this.$message.info({ message: this.$t('rules.selectUploadImage') });
                            return;
                        } else {
                            updateImageBanner(this.editForm).then((res) => {
                                if (res.data == 1) {
                                    this.$message.success({ message: this.$t('message.updateSuccess') });
                                    this.setImageList();
                                    this.editFormVisible = false;
                                } else {
                                    this.$message.error({ message: this.$t('message.updateFail') });
                                }
                            }).catch((res) => {
                                this.$message.error({ message: res });
                            });
                        }
                    }
                } else {
                    //console.log('error submit!!');
                }
            });
        },
    },
}
</script>

<style lang="scss" scoped>
@import "~@/styles/admin-common.scss";
</style>


<style>
.demo-table-expand {
    font-size: 0;
    padding: 0px 50px;
}

.demo-table-expand label {
    width: 90px;
    color: #99a9bf;
    font-weight: bolder;
}

.demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
}
</style>