<template>
    <div id="app">

        <!-- 查询表单 -->
        <el-form :inline="true" :model="queryForm" class="demo-form-inline">
            <el-form-item label="">
                <el-input v-model="queryForm.name" @blur="queryForm.name = $event.target.value.trim()"
                    :placeholder="$t('table.name')" auto-complete="off" clearable></el-input>
            </el-form-item>
            <el-form-item label="" prop="scId">
                <el-select v-model="queryForm.scId" :placeholder="$t('table.selectSecondCategory')" clearable>
                    <el-option :label="item.name" :value="item.id" v-for="item in secondCategoryList"
                        :key="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleQuery()">{{ $t('table.search') }}</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleAdd()" v-if="permiss">{{ $t('table.add') }}</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleExports()" v-if="permiss">{{ $t('table.exports') }}</el-button>
            </el-form-item>
            <el-form-item>
                <el-upload ref="importExcel" :action="importAction" :limit="1" :on-remove="handleRemoveExcel"
                    :on-success="handleImportExcelSuccess" accept=".xlsx,.xls" :before-upload="handleBeforeExcel"
                    :on-change="handleChangeExcel">
                    <el-button slot="trigger" type="primary">{{ $t('table.import') }}</el-button>
                </el-upload>
            </el-form-item>
        </el-form>

        <!-- 数据表格 -->
        <el-table :data="networkingList" border :stripe="true" style="width: 100%" max-height="590"
            @selection-change="handleSelectionChange" highlight-selection-row highlight-current-row>
            <el-table-column type="selection" min-width="5%">
            </el-table-column>
            <el-table-column type="index" label="#" :index="handleIndex" min-width="5%">
            </el-table-column>
            <el-table-column prop="name" :label="$t('table.name')" min-width="10%" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="scName" :label="$t('table.secondCategory')" min-width="10%"
                :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="tcName" :label="$t('table.threeCategory')" min-width="10%"
                :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column :label="$t('table.cover')" min-width="15%">
                <template slot-scope="scope">
                    <el-button size="mini" type="primary" @click="handleEditCover(scope.$index, scope.row)"
                        v-if="permiss">{{
                            $t('table.edit') }}</el-button>
                    <el-button size="mini" type="primary" @click="handleViewCover(scope.$index, scope.row)">{{
                        $t('table.preview') }}</el-button>
                </template>
            </el-table-column>
            <el-table-column :label="$t('table.exhibit')" min-width="20%">
                <template slot-scope="scope">
                    <el-button size="mini" type="success" @click="handleAddExhibit(scope.$index, scope.row)"
                        v-if="permiss">{{
                            $t('table.add') }}</el-button>
                    <el-button size="mini" type="success" @click="handleEditExhibit(scope.$index, scope.row)"
                        v-if="permiss">{{
                            $t('table.edit') }}</el-button>
                    <el-button size="mini" type="success" @click="handleViewExhibit(scope.$index, scope.row)">{{
                        $t('table.preview') }}</el-button>
                </template>
            </el-table-column>
            <el-table-column :label="$t('table.operation')" min-width="25%">
                <template slot-scope="scope">
                    <el-button size="mini" type="info" @click="handleDetails(scope.$index, scope.row)">{{
                        $t('table.detail') }}</el-button>
                    <el-button size="mini" type="primary" @click="handleExport(scope.$index, scope.row)">{{
                        $t('table.export') }}</el-button>
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)" v-if="permiss">{{
                        $t('table.edit') }}</el-button>
                    <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)"
                        v-if="permiss">{{ $t('table.delete') }}</el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- 工具分页栏 -->
        <div class="toolbar">
            <el-button type="danger" size="small" @click="handleDeletes()" v-if="permiss">{{ $t('table.deletes')
                }}</el-button>
            <!-- 分页列表 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
                :current-page="pageNum" :page-sizes="[10, 20, 50]" :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>

        <!-- 详情 -->
        <el-dialog :title="$t('table.detail')" :visible.sync="detailsVisible" :destroy-on-close="true" width="60%">
            <el-descriptions title="" :column="1" border :contentStyle="{ 'white-space': 'pre-wrap' }"
                :label-style="{ 'white-space': 'nowrap' }">
                <el-descriptions-item :label="$t('table.name')">{{ networking.name }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('table.notes')">{{ networking.notes }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('table.secondCategory')">{{ networking.scName }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('table.threeCategory')">{{ networking.tcName }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('table.createTime')">{{ networking.createTime }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('table.updateTime')">{{ networking.updateTime }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('table.keyFeatures')">{{ networking.keyFeatures }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('table.standardComplies')">{{ networking.standardComplies }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('table.interfaces')">{{ networking.interfaces }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('table.richLedIndicators')">{{ networking.richLedIndicators }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('table.macAddressTable')">{{ networking.macAddressTable }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('table.jumboFrame')">{{ networking.jumboFrame }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('table.bufferMemory')">{{ networking.bufferMemory }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('table.emissionSafety')">{{ networking.emissionSafety }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('table.operatingTemperature')">{{ networking.operatingTemperature }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('table.operatingHumidity')">{{ networking.operatingHumidity }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('table.dimension')">{{ networking.dimension }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('table.powerSupply')">{{ networking.powerSupply }}
                </el-descriptions-item>
            </el-descriptions>
        </el-dialog>

        <!-- 添加/编辑表单 -->
        <el-dialog :title="operation ? $t('table.add') : $t('table.edit')" :visible.sync="editFormVisible"
            @close="handleClose()" :destroy-on-close="true" width="60%">
            <el-form ref="editForm" :model="editForm" :rules="editRules" label-position="right" label-width="160px">
                <el-form-item :label="$t('table.name')" prop="name">
                    <el-input v-model="editForm.name" @blur="editForm.name = $event.target.value.trim()"
                        auto-complete="off" maxlength="20"></el-input>
                </el-form-item>
                <el-form-item :label="$t('table.notes')" prop="notes">
                    <el-input v-model="editForm.notes" @blur="editForm.notes = $event.target.value.trim()"
                        auto-complete="off" maxlength="100"></el-input>
                </el-form-item>
                <el-form-item :label="$t('table.secondCategory')" prop="scId">
                    <el-select v-model="editForm.scId" :placeholder="$t('table.selectSecondCategory')"
                        @change="handleScIdChange">
                        <el-option :label="item.name" :value="item.id" v-for="item in secondCategoryList"
                            :key="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('table.threeCategory')" prop="tcId">
                    <el-select v-model="editForm.tcId" :placeholder="$t('table.selectThreeCategory')" clearable>
                        <el-option :label="item.name" :value="item.id" v-for="item in threeCategoryList"
                            :key="item.id"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('table.keyFeatures')" prop="keyFeatures">
                    <el-input type="textarea" autosize v-model="editForm.keyFeatures"
                        @blur="editForm.keyFeatures = $event.target.value.trim()" auto-complete="off"
                        maxlength="1500"></el-input>
                </el-form-item>
                <el-form-item :label="$t('table.standardComplies')" prop="standardComplies">
                    <el-input type="textarea" autosize v-model="editForm.standardComplies"
                        @blur="editForm.standardComplies = $event.target.value.trim()" auto-complete="off"
                        maxlength="500"></el-input>
                </el-form-item>
                <el-form-item :label="$t('table.interfaces')" prop="interfaces">
                    <el-input type="textarea" autosize v-model="editForm.interfaces"
                        @blur="editForm.interfaces = $event.target.value.trim()" auto-complete="off"
                        maxlength="500"></el-input>
                </el-form-item>
                <el-form-item :label="$t('table.richLedIndicators')" prop="richLedIndicators">
                    <el-input type="textarea" autosize v-model="editForm.richLedIndicators"
                        @blur="editForm.richLedIndicators = $event.target.value.trim()" auto-complete="off"
                        maxlength="500"></el-input>
                </el-form-item>
                <el-form-item :label="$t('table.macAddressTable')" prop="macAddressTable">
                    <el-input type="textarea" autosize v-model="editForm.macAddressTable"
                        @blur="editForm.macAddressTable = $event.target.value.trim()" auto-complete="off"
                        maxlength="500"></el-input>
                </el-form-item>
                <el-form-item :label="$t('table.jumboFrame')" prop="jumboFrame">
                    <el-input type="textarea" autosize v-model="editForm.jumboFrame"
                        @blur="editForm.jumboFrame = $event.target.value.trim()" auto-complete="off"
                        maxlength="500"></el-input>
                </el-form-item>
                <el-form-item :label="$t('table.bufferMemory')" prop="bufferMemory">
                    <el-input type="textarea" autosize v-model="editForm.bufferMemory"
                        @blur="editForm.bufferMemory = $event.target.value.trim()" auto-complete="off"
                        maxlength="500"></el-input>
                </el-form-item>
                <el-form-item :label="$t('table.emissionSafety')" prop="emissionSafety">
                    <el-input type="textarea" autosize v-model="editForm.emissionSafety"
                        @blur="editForm.emissionSafety = $event.target.value.trim()" auto-complete="off"
                        maxlength="500"></el-input>
                </el-form-item>
                <el-form-item :label="$t('table.operatingTemperature')" prop="operatingTemperature">
                    <el-input type="textarea" autosize v-model="editForm.operatingTemperature"
                        @blur="editForm.operatingTemperature = $event.target.value.trim()" auto-complete="off"
                        maxlength="500"></el-input>
                </el-form-item>
                <el-form-item :label="$t('table.operatingHumidity')" prop="operatingHumidity">
                    <el-input type="textarea" autosize v-model="editForm.operatingHumidity"
                        @blur="editForm.operatingHumidity = $event.target.value.trim()" auto-complete="off"
                        maxlength="500"></el-input>
                </el-form-item>
                <el-form-item :label="$t('table.dimension')" prop="dimension">
                    <el-input type="textarea" autosize v-model="editForm.dimension"
                        @blur="editForm.dimension = $event.target.value.trim()" auto-complete="off"
                        maxlength="500"></el-input>
                </el-form-item>
                <el-form-item :label="$t('table.powerSupply')" prop="powerSupply">
                    <el-input type="textarea" autosize v-model="editForm.powerSupply"
                        @blur="editForm.powerSupply = $event.target.value.trim()" auto-complete="off"
                        maxlength="500"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editFormVisible = false">{{ $t('table.cancel') }}</el-button>
                <el-button type="primary" @click="handleEditSubmit('editForm')">{{ $t('table.confirm') }}</el-button>
            </div>
        </el-dialog>

        <!-- 编辑封面 -->
        <el-dialog :title="$t('table.editCover')" :visible.sync="editCoverVisible" :destroy-on-close="true" width="60%"
            @closed="handleCloseCover()">
            <el-form ref="editCover" :model="editCover" :rules="coverRules" label-position="right" label-width="60px">
                <el-form-item :label="$t('table.cover')" prop="cover">
                    <el-upload action="#" list-type="picture-card" :auto-upload="false" :limit="1"
                        :on-change="handleUploadChangeCover" :on-remove="handleUploadRemoveCover"
                        :on-preview="handlePreviewCover" ref="uploadCover" :http-request="handleUploadCover"
                        accept="image/png,image/jpg,image/jpeg" :before-upload="handleBeforeUploadCover">
                        <i class="el-icon-plus"></i>
                        <div slot="tip" class="el-upload__tip">{{ $t('rules.uploadImage') }}</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editCoverVisible = false">{{ $t('table.cancel') }}</el-button>
                <el-button type="primary" @click="handleEditCoverSubmit('editCover')">{{ $t('table.confirm')
                    }}</el-button>
            </div>
        </el-dialog>

        <!-- 添加展示图 -->
        <el-dialog :title="$t('table.addExhibit')" :visible.sync="addExhibitVisible" :destroy-on-close="true"
            width="60%" @closed="handleCloseAddExhibit()">
            <el-form ref="addExhibit" :model="addExhibit" :rules="exhibitRules" label-position="right"
                label-width="70px">
                <el-form-item :label="$t('table.exhibit')" prop="exhibit">
                    <el-upload action="#" list-type="picture-card" :auto-upload="false" :multiple="true" :limit="10"
                        :on-change="handleUploadChangeExhibit" :on-remove="handleUploadRemoveExhibit"
                        :on-preview="handlePreviewExhibit" ref="uploadExhibit" :http-request="handleUploadExhibit"
                        accept="image/png,image/jpg,image/jpeg" :before-upload="handleBeforeUploadExhibit">
                        <i class="el-icon-plus"></i>
                        <div slot="tip" class="el-upload__tip">{{ $t('rules.uploadImage') }}</div>
                    </el-upload>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="addExhibitVisible = false">{{ $t('table.close') }}</el-button>
                <el-button type="primary" @click="handleAddExhibitSubmit('addExhibit')">{{ $t('table.submit')
                    }}</el-button>
            </div>
        </el-dialog>

        <!-- 编辑展示图：删除，移动 -->
        <el-dialog :title="$t('table.editExhibit')" :visible.sync="editExhibitVisible" :destroy-on-close="true"
            width="60%" @closed="handleCloseEditExhibit()">

            <el-card class="box-card" shadow="never">
                <div v-for="(item, index) in editExhibit.exhibit" :key="index" class="text item">
                    <el-row style="margin-bottom: 5px;">
                        <el-col :span="12">
                            {{ item }}
                        </el-col>
                        <el-col :span="12">
                            <el-button size="mini" type="primary" @click="handleEditExhibitPreview(item)"
                                v-if="permiss">{{
                                    $t('table.preview') }}</el-button>
                            <el-button size="mini" type="danger" @click="handleDeleteExhibit(item)" v-if="permiss">{{
                                $t('table.delete') }}</el-button>
                            <el-button size="mini" type="primary" :disabled="index == 0"
                                @click="handleToTop(item, index)" v-if="permiss">{{
                                    $t('table.toTop') }}</el-button>
                            <el-button size="mini" type="info" :disabled="index == 0" @click="handleMoveUp(index)"
                                v-if="permiss">{{
                                    $t('table.moveUp') }}</el-button>
                            <el-button size="mini" type="warning" :disabled="index == editExhibit.exhibit.length - 1"
                                @click="handleMoveDown(index)" v-if="permiss">{{
                                    $t('table.moveDown') }}</el-button>
                            <el-button size="mini" type="success" :disabled="index == editExhibit.exhibit.length - 1"
                                @click="handleToBottom(item, index)" v-if="permiss">{{
                                    $t('table.toBottom') }}</el-button>
                        </el-col>
                    </el-row>
                </div>
            </el-card>
        </el-dialog>

        <!-- 放大图 -->
        <el-image style="display: none;" ref="preview" :src="firstUrl" :preview-src-list="urlList"></el-image>
    </div>
</template>

<script>
import {
    getNetworkingByPage, addNetworking, updateNetworking, deleteNetworking, validateNameExist, updateNetworkingCover, addNetworkingExhibit,
    deleteNetworkingExhibit, updateNetworkingExhibit
} from "@/api/networking"
import { getSecondCategoryByFcId } from "@/api/category/secondCategory"
import { getThreeCategoryByScId } from "@/api/category/threeCategory"
import { downloadGet, downloadPost } from '@/utils/fileUtil'
import { baseUrl } from '@/utils/global'

export default {
    name: 'admin-networking',
    computed: {
        editRules() {
            const validateName = (rule, value, callback) => {
                if (this.oldName != value) {
                    if (value.length > 20) {
                        callback(new Error(this.$t('rules.lengthRange20')))
                    } else {
                        let param = {
                            name: value
                        }
                        validateNameExist(param).then(res => {
                            if (!res.data) {
                                callback(new Error(this.$t('rules.nameExist')))
                            } else {
                                callback()
                            }
                        }).catch(function (res) {
                            callback(new Error(this.$t('rules.exception')))
                        });
                    }
                }
                else {
                    callback()
                }
            }
            return {
                name: [
                    { required: true, message: this.$t("rules.inputName"), trigger: 'blur' },
                    { validator: validateName, trigger: 'blur' },
                ],
                scId: [
                    { required: true, message: this.$t("rules.selectSecondCategory"), trigger: ['blur', 'change'] },
                ],
            }
        },
        coverRules() {
            return {
                cover: [
                    //{ required: true, message: this.$t("rules.selectImage"), trigger: ['blur', 'change'] },
                ],
            }
        },
        exhibitRules() {
            return {
                exhibit: [
                    //{ required: true, message: this.$t("rules.selectImage"), trigger: ['blur', 'change'] },
                ],
            }
        }
    },
    data() {
        return {
            loginRole: "",
            permiss: false,
            networkingList: [],
            networking: {
                name: '',
                notes: '',
                scName: '',
                tcName: '',
                createTime: '',
                updateTime: '',
                keyFeatures: '',
                standardComplies: '',
                interfaces: '',
                richLedIndicators: '',
                macAddressTable: '',
                jumboFrame: '',
                bufferMemory: '',
                emissionSafety: '',
                operatingTemperature: '',
                operatingHumidity: '',
                dimension: '',
                powerSupply: '',
            },
            queryForm: {
                name: '',
                scId: '',
            },
            pageNum: 1,
            total: 10,
            pageSize: 10,
            pages: 1,
            operation: true,
            detailsVisible: false,
            editFormVisible: false,
            secondCategoryList: [],
            threeCategoryList: [],
            editForm: {
                id: '',
                name: '',
                notes: '',
                fcId: '',
                scId: '',
                tcId: '',
                keyFeatures: '',
                standardComplies: '',
                interfaces: '',
                richLedIndicators: '',
                macAddressTable: '',
                jumboFrame: '',
                bufferMemory: '',
                emissionSafety: '',
                operatingTemperature: '',
                operatingHumidity: '',
                dimension: '',
                powerSupply: '',
            },
            oldName: '',
            urlList: [""],
            firstUrl: '',
            deleteIds: [],
            editCoverVisible: false,
            editCover: {
                id: '',
                cover: '',
            },
            addExhibitVisible: false,
            addExhibit: {
                id: '',
                exhibit: '',
            },
            editExhibitVisible: false,
            editExhibit: {
                id: '',
                exhibit: [],
            },
            importExcel: '',
            importAction: baseUrl + "/networking/import",
        }
    },
    mounted() {
        this.getSession();
        this.setNetworkingList();
        this.setSecondCategoryList();
    },
    methods: {
        getSession() {
            this.loginRole = this.$cookies.get("role");
            if (this.loginRole == "super admin" || this.loginRole == "networking admin") {
                this.permiss = true;
            } else {
                this.permiss = false;
            }
        },
        //分页获取Networking下产品信息
        setNetworkingList() {
            let params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                queryForm: this.queryForm
            };
            getNetworkingByPage(params).then((res) => {
                this.networkingList = res.data.content;
                this.total = res.data.totalSize;
            }).catch(function (res) { });
        },
        //获取Networking下二级分类
        setSecondCategoryList() {
            getSecondCategoryByFcId(1).then((res) => {
                this.secondCategoryList = res.data;
            }).catch(function (res) { });
        },
        //获取Networking下三级分类
        setThreeCategoryList(scId) {
            getThreeCategoryByScId(scId).then((res) => {
                this.threeCategoryList = res.data;
            }).catch(function (res) { });
        },
        //数据序号翻页累加
        handleIndex(index) {
            const pageNum = this.pageNum // 当前页码
            const pageSize = this.pageSize // 每页条数
            return index + 1 + (pageNum - 1) * pageSize
        },
        //修改每页大小
        handleSizeChange(val) {
            if (this.pageSize != val) {
                this.pageSize = val;
                this.pageNum = 1;
                this.setNetworkingList();
            }
        },
        //翻页
        handleCurrentChange(val) {
            this.pageNum = val;
            this.setNetworkingList();
        },
        //查询
        handleQuery() {
            this.pageNum = 1;
            this.setNetworkingList();
        },
        //导出全部数据
        handleExports() {
            this.$confirm(this.$t('message.exportsConfirm'), this.$t('message.tip'), {
                type: "warning"
            }).then(() => {
                downloadGet("/networking/exports", "networking.xlsx")
            }).catch(() => { });
        },
        //导出单条数据
        handleExport(index, row) {
            this.$confirm(this.$t('message.exportConfirm'), this.$t('message.tip'), {
                type: "warning"
            }).then(() => {
                const fileName = row.name + ".xlsx";
                downloadPost("/networking/export", row.id, fileName)
            }).catch(() => { });
        },
        //************************* 导入数据 ***************************** */
        handleRemoveExcel(file, fileList) {
        },
        handleChangeExcel(file, fileList) {
        },
        handleBeforeExcel(file) {
        },
        handleImportExcelSuccess(res, file) {
            if (res.data >= 1) {
                this.$message.success({ message: this.$t('message.importSuccess') });
                this.setNetworkingList();
            } else {
                let msgList = [];
                if (res.data != null && res.data != "") msgList.push("Networking : [ " + res.data + " ]");

                let message = "";
                if (msgList != null && msgList.length > 0) {
                    message = this.$t('message.addRepeatData') + `<br/><br/>${msgList.join(
                        "<br/><br/>"
                    )}`;
                } else {
                    message = res.data;
                }

                this.$message({
                    dangerouslyUseHTMLString: true,
                    message: message,
                    type: "warning"
                });
            }
            this.$refs.importExcel.$refs['upload-inner'].onRemove(file);

        },
        //*************************** 编辑封面 **********************************
        handleEditCover(index, row) {
            this.editCover.id = row.id;
            this.editCoverVisible = true;
        },
        handleUploadChangeCover(file, fileList) {
            if (fileList.length !== 0) {
                const isLt2M = file.size / 1024 / 1024 < 2
                if (!isLt2M) {
                    this.$message.info({ message: this.$t('rules.imageOutRange') });
                    this.$refs.uploadCover.$refs['upload-inner'].onRemove(file);
                    return;
                }
                this.editCover.cover = file;
            }
        },
        handleUploadRemoveCover(file, fileList) {
            if (fileList.length === 0) {
                this.editCover.cover = null
            }
        },
        handlePreviewCover(file) {
            this.urlList = []
            this.urlList.push(file.url);
            this.$refs.preview.clickHandler();
        },
        handleUploadCover(file) {

        },
        handleBeforeUploadCover(file) {  //手动上传该方法无用

        },
        handleCloseCover() {
            Object.keys(this.editCover).forEach(key => (this.editCover[key] = null));
        },
        handleEditCoverSubmit(form) {
            this.$refs[form].validate((valid) => {
                if (valid) {
                    this.$refs.uploadCover.submit()
                    if (this.editCover.cover) {
                        var fd = new FormData() //携带文件必须使用此对象
                        fd.append("file", this.editCover.cover.raw); //把文件实体添加到表单对象
                        fd.append("id", this.editCover.id);

                        updateNetworkingCover(fd).then((res) => {
                            if (res.data == 1) {
                                this.$message.success({ message: this.$t('message.updateSuccess') });
                                this.setNetworkingList();
                                this.editCoverVisible = false;
                            } else {
                                this.$message.error({ message: res.data });
                            }
                        }).catch((res) => {
                            this.$message.warning({ message: res });
                        });
                    } else {
                        this.$message.info({ message: this.$t('rules.selectUploadImage') });
                        return;
                    }
                }
            });
        },

        //查看封面
        handleViewCover(index, row) {
            if (row.cover == null || row.cover.length == 0) {
                this.$message.info({ message: this.$t('message.coverNotFound') });
                return;
            }
            this.urlList = [];
            this.urlList.push(row.cover);
            this.$refs.preview.clickHandler();
        },

        //**************************** 添加展示图 ****************************
        handleAddExhibit(index, row) {
            this.addExhibit.id = row.id;
            this.addExhibitVisible = true;
        },
        handleUploadChangeExhibit(file, fileList) {
            if (fileList.length !== 0) {
                const isLt2M = file.size / 1024 / 1024 < 2
                if (!isLt2M) {
                    this.$message.info({ message: this.$t('rules.imageOutRange') });
                    this.$refs.uploadExhibit.$refs['upload-inner'].onRemove(file);
                    return;
                }
                this.addExhibit.exhibit = fileList;
            }
        },
        handleUploadRemoveExhibit(file, fileList) {
            if (fileList.length === 0) {
                this.addExhibit.exhibit = null
            }
        },
        handlePreviewExhibit(file) {
            this.urlList = []
            this.urlList.push(file.url);
            this.$refs.preview.clickHandler();
        },
        handleUploadExhibit(file) {

        },
        handleBeforeUploadExhibit(file) {  //手动上传该方法无用

        },
        handleCloseAddExhibit() {
            Object.keys(this.addExhibit).forEach(key => (this.addExhibit[key] = null));
        },
        //添加展示图
        handleAddExhibitSubmit(form) {
            this.$refs[form].validate((valid) => {
                if (valid) {
                    this.$refs.uploadExhibit.submit()
                    if (this.addExhibit.exhibit) {
                        var fd = new FormData() //携带文件必须使用此对象

                        this.addExhibit.exhibit.forEach((file) => {
                            fd.append('files', file.raw)
                        })
                        fd.append("id", this.addExhibit.id);

                        addNetworkingExhibit(fd).then((res) => {
                            if (res.data == 1) {
                                this.$message.success({ message: this.$t('message.addSuccess') });
                                this.setNetworkingList();
                                this.addExhibitVisible = false;
                            } else {
                                this.$message.error({ message: res.data });
                            }
                        }).catch((res) => {
                            this.$message.warning({ message: res });
                        });
                    } else {
                        this.$message.info({ message: this.$t('rules.selectUploadImage') });
                        return;
                    }
                }
            });
        },

        //*************************** 编辑展示图 ******************************
        handleEditExhibit(index, row) {
            if (row.exhibit == null || row.exhibit.length == 0) {
                this.$message.info({ message: this.$t('message.exhibitNotFound') });
                return;
            }
            this.editExhibit.id = row.id;
            this.editExhibit.exhibit = row.exhibit.split(",")
            this.editExhibitVisible = true;
        },
        //关闭窗口时清除数据
        handleCloseEditExhibit() {
            Object.keys(this.editExhibit).forEach(key => (this.editExhibit[key] = null));
            this.setNetworkingList();
        },
        //预览
        handleEditExhibitPreview(url) {
            this.urlList = []
            this.urlList.push(url)
            this.$refs.preview.clickHandler();
        },
        removeItem(url) {
            let index;
            for (var item in this.editExhibit.exhibit) {
                if (url == this.editExhibit.exhibit[item]) {
                    index = item;
                    break;
                }
            }
            this.editExhibit.exhibit.splice(index, 1);
        },

        //删除展示图
        handleDeleteExhibit(url) {
            this.$confirm(this.$t('message.deleteConfirm'), this.$t('message.tip'), {
                type: "warning"
            }).then(() => {
                let params = {
                    id: this.editExhibit.id,
                    url: url,
                }
                deleteNetworkingExhibit(params).then(res => {
                    if (res.data == 1) {
                        this.$message.success({ message: this.$t('message.deleteSuccess') });
                        this.removeItem(url)
                    } else {
                        this.$message.error({ message: this.$t('message.deleteFail') });
                    }
                }).catch((res) => {
                    this.$message.error({ message: res });
                });
            }).catch(() => {
                this.$message.info({ message: this.$t('message.deleteCancel') });
            });
        },
        //将该展示图置顶
        handleToTop(item, index) {
            this.editExhibit.exhibit.splice(index, 1)
            this.editExhibit.exhibit.unshift(item)
            this.moveExhibit()
        },
        //将该展示图上移
        handleMoveUp(index) {
            let arr = this.editExhibit.exhibit;
            arr.splice(index - 1, 1, ...arr.splice(index, 1, arr[index - 1]));
            this.moveExhibit()
        },
        //将该展示图下移
        handleMoveDown(index) {
            let arr = this.editExhibit.exhibit;
            arr.splice(index, 1, ...arr.splice(index + 1, 1, arr[index]));
            this.moveExhibit()
        },
        //将该展示图置底
        handleToBottom(item, index) {
            this.editExhibit.exhibit.splice(index, 1)
            this.editExhibit.exhibit.push(item)
            this.moveExhibit()
        },
        //移动
        moveExhibit() {
            let params = {
                id: this.editExhibit.id,
                exhibit: this.editExhibit.exhibit,
            }
            updateNetworkingExhibit(this.editExhibit).then((res) => {
                if (res.data == 1) {
                    this.$message.success({ message: this.$t('message.updateSuccess') });
                } else {
                    this.$message.error({ message: this.$t('message.updateFail') });
                }
            }).catch((res) => {
                this.$message.warning({ message: res });
            });
        },
        //查看展示图
        handleViewExhibit(index, row) {
            if (row.exhibit == null || row.exhibit.length == 0) {
                this.$message.info({ message: this.$t('message.exhibitNotFound') });
                return;
            }
            this.urlList = []
            let exhibit = row.exhibit.split(",")
            for (var item in exhibit) {
                this.urlList.push(exhibit[item])
            }
            this.$refs.preview.clickHandler();
        },
        //查看详情
        handleDetails(index, row) {
            this.detailsVisible = true;
            this.networking.name = row.name;
            this.networking.notes = row.notes;
            this.networking.scName = row.scName;
            this.networking.tcName = row.tcName;
            this.networking.createTime = row.createTime;
            this.networking.updateTime = row.updateTime;
            this.networking.keyFeatures = row.keyFeatures;
            this.networking.standardComplies = row.standardComplies;
            this.networking.interfaces = row.interfaces;
            this.networking.richLedIndicators = row.richLedIndicators;
            this.networking.macAddressTable = row.macAddressTable;
            this.networking.jumboFrame = row.jumboFrame;
            this.networking.bufferMemory = row.bufferMemory;
            this.networking.emissionSafety = row.emissionSafety;
            this.networking.operatingTemperature = row.operatingTemperature;
            this.networking.operatingHumidity = row.operatingHumidity;
            this.networking.dimension = row.dimension;
            this.networking.powerSupply = row.powerSupply;
        },
        //初始化编辑窗口
        initEditDialog(operation) {
            this.operation = operation;
            this.editFormVisible = true;
            this.setSecondCategoryList();
            this.$nextTick(() => {
                this.$refs.editForm.clearValidate();
            })
        },
        //监听编辑窗口的二级分类下拉框，设置三级分类下拉框
        handleScIdChange(val) {
            this.editForm.tcId = '';
            this.setThreeCategoryList(val);
        },
        //编辑信息
        handleEdit(index, row) {
            this.editForm.id = row.id;
            this.editForm.name = row.name;
            this.editForm.notes = row.notes;
            this.editForm.fcId = row.fcId;
            this.editForm.scId = row.scId;
            this.editForm.tcId = row.tcId;
            this.editForm.keyFeatures = row.keyFeatures;
            this.editForm.standardComplies = row.standardComplies;
            this.editForm.interfaces = row.interfaces;
            this.editForm.richLedIndicators = row.richLedIndicators;
            this.editForm.macAddressTable = row.macAddressTable;
            this.editForm.jumboFrame = row.jumboFrame;
            this.editForm.bufferMemory = row.bufferMemory;
            this.editForm.emissionSafety = row.emissionSafety;
            this.editForm.operatingTemperature = row.operatingTemperature;
            this.editForm.operatingHumidity = row.operatingHumidity;
            this.editForm.dimension = row.dimension;
            this.editForm.powerSupply = row.powerSupply;
            this.oldName = row.name;
            this.setThreeCategoryList(row.scId);
            this.initEditDialog(false);
        },
        //删除
        delete(ids) {
            this.$confirm(this.$t('message.deleteConfirm'), this.$t('message.tip'), {
                type: "warning"
            }).then(() => {
                deleteNetworking(ids).then((res) => {
                    if (res.data >= 1) {
                        this.$message.success({ message: this.$t('message.deleteSuccess') });
                        this.setNetworkingList();
                    } else {
                        this.$message.error({ message: this.$t('message.deleteFail') });
                    }
                }).catch((res) => {
                    this.$message.error({ message: res });
                });
            }).catch(() => {
                this.$message.info({ message: this.$t('message.deleteCancel') });
            });
        },
        //删除单条数据
        handleDelete(index, row) {
            let deleteId = [row.id];
            this.delete(deleteId);
        },
        //批量删除数据
        handleDeletes() {
            if (this.deleteIds.length == 0) {
                this.$message.info({ message: this.$t('message.deleteDataSelect') });
            } else {
                this.delete(this.deleteIds);
            }
        },
        //监听多选框
        handleSelectionChange(val) {
            this.deleteIds = [];
            val.forEach(item => {
                this.deleteIds.push(item.id);
            });
        },
        //添加数据
        handleAdd() {
            this.initEditDialog(true);
        },
        //清空添加/编辑窗口数据
        handleClose() {
            // 清空添加/编辑表单数据
            Object.keys(this.editForm).forEach(key => (this.editForm[key] = ''));
            this.oldName = '';
        },
        //添加/编辑提交
        handleEditSubmit(form) {
            this.$refs[form].validate((valid) => {
                if (valid) {
                    if (this.operation) {
                        addNetworking(this.editForm).then((res) => {
                            if (res.data == 1) {
                                this.$message.success({ message: this.$t('message.addSuccess') });
                                this.pageNum = 1;
                                this.setNetworkingList();
                                this.editFormVisible = false
                            } else {
                                this.$message.error({ message: this.$t('message.addFail') });
                            }
                        }).catch((res) => {
                            this.$message.error({ message: res });
                        });
                    } else {
                        updateNetworking(this.editForm).then((res) => {
                            if (res.data == 1) {
                                this.$message.success({ message: this.$t('message.updateSuccess') });
                                this.setNetworkingList();
                                this.editFormVisible = false
                            } else {
                                this.$message.error({ message: this.$t('message.updateFail') });
                            }
                        }).catch((res) => {
                            this.$message.error({ message: res });
                        });
                    }
                } else {
                    //console.log('error submit!!');
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/admin-common.scss";
</style>