<template>
    <div id="app">
        <panel-group />

        <el-row :gutter="40">
            <el-col :xs="24" :sm="24" :lg="12">
                <div class="chart-wrapper">
                    <pie-chart />
                </div>
            </el-col>

            <el-col :xs="24" :sm="24" :lg="12">
                <div class="chart-wrapper">
                    <pie-chart2 />
                </div>
            </el-col>
        </el-row>

        <!-- <el-row :gutter="40">
            <el-col :xs="24" :sm="24" :lg="12">
                <div class="chart-wrapper">
                    <el-calendar v-model="value"></el-calendar>
                </div>
            </el-col>
        </el-row> -->
    </div>


</template>
<script>
import PanelGroup from './components/PanelGroup'
import PieChart from './components/PieChart'
import PieChart2 from './components/PieChart2'

export default {
    name: 'admin-home',
    components: {
        PanelGroup,
        PieChart,
        PieChart2
    },
    data() {
        return {
            value: new Date(),
        }
    },
    mounted() {
    },
    methods: {

    }
}
</script>

<style lang="scss" scoped>
#app {
    padding: 40px;
    background-color: #f0f2f5;
    position: relative;

    .chart-wrapper {
        background: #fff;
        padding: 16px 16px 0;
        margin-bottom: 32px;
    }
}

@media (max-width:1024px) {
    .chart-wrapper {
        padding: 8px;
    }
}
</style>