<template>
    <div id="app">

        <!-- 查询表单 -->
        <el-form :inline="true" :model="queryForm" class="demo-form-inline">
            <el-form-item label="">
                <el-input v-model="queryForm.name" @blur="queryForm.name = $event.target.value.trim()" :placeholder="$t('table.name')"
                    auto-complete="off" clearable></el-input>
            </el-form-item>
            <el-form-item label="">
                <el-select v-model="queryForm.scId" :placeholder="$t('table.selectSecondCategory')" clearable>
                    <el-option :label="item.name" :value="item.id" v-for="item in secondCategoryList"
                        :key="item.id"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleQuery()">{{$t('table.search')}}</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleAdd()" v-if="permiss">{{$t('table.add')}}</el-button>
            </el-form-item>
        </el-form>

        <!-- 数据表格 -->
        <el-table :data="threeCategoryList" border :stripe="true" style="width: 100%" max-height="590"
            @selection-change="handleSelectionChange" highlight-selection-row highlight-current-row>
            <el-table-column type="selection" min-width="5%">
            </el-table-column>
            <el-table-column type="index" label="#" :index="handleIndex" min-width="5%">
            </el-table-column>
            <el-table-column prop="name" :label="$t('table.name')" min-width="15%" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="scName" :label="$t('table.secondCategory')" min-width="15%" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="createTime" :label="$t('table.createTime')" min-width="20%" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="updateTime" :label="$t('table.updateTime')" min-width="20%" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column :label="$t('table.operation')" min-width="20%" v-if="permiss">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)">{{$t('table.edit')}}</el-button>
                    <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)">{{$t('table.delete')}}</el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- 工具栏 -->
        <div class="toolbar">
            <el-button type="danger" size="small" @click="handleDeletes()" v-if="permiss">{{$t('table.deletes')}}</el-button>
            <!-- 分页列表 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
                :current-page="pageNum" :page-sizes="[10, 20, 50]" :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>

        <el-dialog :title="operation ? $t('table.add') : $t('table.edit')" :visible.sync="editFormVisible" @close="handleClose()"
            :destroy-on-close="true">
            <el-form ref="editForm" :model="editForm" :rules="editRules" label-position="right" label-width="130px">

                <el-form-item :label="$t('table.name')" prop="name">
                    <el-input v-model="editForm.name" @blur="editForm.name = $event.target.value.trim()"
                        auto-complete="off" maxlength="100"></el-input>
                </el-form-item>
                <el-form-item :label="$t('table.secondCategory')" prop="scId">
                    <el-select v-model="editForm.scId" :placeholder="$t('table.selectSecondCategory')" style="width:100%;">
                        <el-option :label="item.name" :value="item.id" v-for="item in secondCategoryList"
                            :key="item.id"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editFormVisible = false">{{$t('table.cancel')}}</el-button>
                <el-button type="primary" @click="handleEditSubmit('editForm')">{{$t('table.confirm')}}</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { getThreeCategoryByPage, deleteThreeCategory, updateThreeCategory, addThreeCategory, validateNameAndScId } from "@/api/category/threeCategory"
import { getSecondCategoryList,getSecondCategoryByFcIdAll } from "@/api/category/secondCategory"

export default {
    name: 'admin-threeCategory',
    computed: {
        editRules() {
            return{
                name: [
                    { required: true, message: this.$t('rules.inputName'), trigger: 'blur' },
                ],
                scId: [
                    { required: true, message: this.$t('rules.selectSecondCategory'), trigger: ["blur", 'change'] }
                ]
            }
        }
    },
    data() {
        return {
            loginRole: "",
            permiss: false,
            threeCategoryList: [],
            secondCategoryList: [],
            queryForm: {
                name: '',
                fcId: '',
                scId: ''
            },
            pageNum: 1,
            total: 10,
            pageSize: 10,
            pages: 1,
            deleteIds: [],
            operation: true,
            editFormVisible: false,
            editForm: {
                id: '',
                name: '',
                scId: '',
            },
            oldName: '',
            oldScId: ''
        }
    },
    mounted() {
        this.getSession();
        this.setThreeCategoryList();
        this.setSecondCategoryList();
    },
    methods: {
        getSession() {
            this.loginRole = this.$cookies.get("role");
            if (this.loginRole == "super admin" || this.loginRole == "advertising admin" || this.loginRole == "networking admin") {
                this.permiss = true;
            } else {
                this.permiss = false;
            }
        },
        handleIndex(index) {
            const pageNum = this.pageNum // 当前页码
            const pageSize = this.pageSize // 每页条数
            return index + 1 + (pageNum - 1) * pageSize
        },
        setSecondCategoryList() {
            getSecondCategoryByFcIdAll(Number(this.queryForm.fcId)).then((res) => {
                this.secondCategoryList = res.data;
            }).catch((res) => {
                this.$message.warning({ message: res });
            });
        },
        setThreeCategoryList() {
            if (this.loginRole == "networking admin") {
                this.queryForm.fcId = 1
            } else if (this.loginRole == "advertising admin") {
                this.queryForm.fcId = 2
            }
            let params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                queryForm: this.queryForm
            };
            getThreeCategoryByPage(params).then((res) => {
                this.threeCategoryList = res.data.content;
                this.total = res.data.totalSize;
            }).catch((res) => {
                this.$message.warning({ message: res });
            });
        },
        delete(ids) {
            this.$confirm(this.$t('message.deleteConfirm'), this.$t('message.tip'), {
                type: "warning"
            }).then(() => {
                deleteThreeCategory(ids).then((res) => {
                    if (res.data >= 1) {
                        this.$message.success({ message: this.$t('message.deleteSuccess') });
                        this.setThreeCategoryList();
                    } else {
                        let msgList = [];
                        if(res.data.Advertising != null && res.data.Advertising != "" ) msgList.push("Advertising : [ " + res.data.Advertising + " ]");
                        if(res.data.Networking != null && res.data.Networking != "" ) msgList.push("Networking : [ " + res.data.Networking + " ]");
                        
                        let message = "";
                        if (msgList != null && msgList.length > 0) {
                            message = this.$t('message.deleteReferencedData')+`<br/><br/>${msgList.join(
                                "<br/><br/>"
                            )}`;
                        }else{
                            message = res.data;
                        }
                        
                        this.$message({
                            dangerouslyUseHTMLString: true,
                            message: message,
                            type: "warning"
                        });
                    }
                }).catch((res) => {
                    this.$message.warning({ message: res });
                });
            }).catch(() => {
                this.$message.info({ message: this.$t('message.deleteCancel') });
            });
        },
        handleDelete(index, row) {
            let deleteId = [row.id];
            this.delete(deleteId);
        },
        handleDeletes() {
            if(this.deleteIds.length == 0){
                this.$message.info({ message: this.$t('message.deleteDataSelect') });
            }else{
                this.delete(this.deleteIds);
            }
        },
        handleSelectionChange(val) {
            this.deleteIds = [];
            val.forEach(item => {
                this.deleteIds.push(item.id);
            });
        },
        handleQuery() {
            this.pageNum = 1;
            this.setThreeCategoryList();
        },
        handleSizeChange(val) {
            if (this.pageSize != val) {
                this.pageSize = val;
                this.pageNum = 1;
                this.setThreeCategoryList();
            }
        },
        handleCurrentChange(val) {
            this.pageNum = val;
            this.setThreeCategoryList();
        },
        initEditDialog(operation) {
            this.operation = operation;
            this.editFormVisible = true;
            this.$nextTick(() => {
                this.$refs.editForm.clearValidate();
            })
        },
        handleAdd() {
            this.initEditDialog(true)
        },
        handleClose() {
            Object.keys(this.editForm).forEach(key => (this.editForm[key] = ''));
        },
        handleEdit(index, row) {
            this.editForm.id = row.id;
            this.editForm.name = row.name;
            this.editForm.scId = row.scId;
            this.oldName = row.name;
            this.oldScId = row.scId;
            this.initEditDialog(false)
        },
        add(value) {
            addThreeCategory(value).then((res) => {
                if (res.data == 1) {
                    this.$message.success({ message: this.$t('message.addSuccess') });
                    this.pageNum = 1;
                    this.setThreeCategoryList();
                    this.editFormVisible = false;
                } else {
                    this.$message.error({ message: this.$t('message.addFail') });
                }
            }).catch((res) => {
                this.$message.warning({ message: res });
            });
        },
        update(value) {
            updateThreeCategory(value).then((res) => {
                if (res.data == 1) {
                    this.$message.success({ message: this.$t('message.updateSuccess') });
                    this.setThreeCategoryList();
                    this.editFormVisible = false;
                } else {
                    this.$message.error({ message: this.$t('message.updateFail') });
                }
            }).catch((res) => {
                this.$message.warning({ message: res });
            });
        },
        handleEditSubmit(form) {
            this.$refs[form].validate((valid) => {
                if (valid) {
                    if (this.operation) {
                        validateNameAndScId(JSON.stringify(this.editForm)).then((res) => {
                            if (!res.data) {
                                this.$message.info({ message: this.$t('message.secondCategoryUnderExist') });
                            } else {
                                this.add(JSON.stringify(this.editForm));
                            }
                        }).catch((res) => {
                            this.$message.warning({ message: res });
                        });
                    } else {
                        if (this.oldName != this.editForm.name || this.oldScId != this.editForm.scId) {
                            validateNameAndScId(JSON.stringify(this.editForm)).then((res) => {
                                if (!res.data) {
                                    this.$message.info({ message: this.$t('message.secondCategoryUnderExist') });
                                } else {
                                    this.update(JSON.stringify(this.editForm));
                                }
                            }).catch((res) => {
                                this.$message.warning({ message: res });
                            });
                        } else {
                            this.update(JSON.stringify(this.editForm));
                        }
                    }
                } else {
                    //console.log("submit error!")
                }
            });
        }
    }
}
</script>


<style lang="scss" scoped>
@import "~@/styles/admin-common.scss";
</style>