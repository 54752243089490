<template>
    <el-row :gutter="40" class="panel-group">
        <el-col :xs="12" :sm="12" :lg="8" class="card-panel-col">
            <div class="card-panel">
                <div class="card-panel-icon-wrapper icon-message">
                    <fa-icon :icon="['fas', 'paper-plane']" />
                </div>
                <div class="card-panel-description">
                    <div class="card-panel-text">
                        Messages
                    </div>
                    <count-to :start-val="0" :end-val="messageCount" :duration="2600" class="card-panel-num" />
                </div>
            </div>
        </el-col>
        <el-col :xs="12" :sm="12" :lg="8" class="card-panel-col">
            <div class="card-panel">
                <div class="card-panel-icon-wrapper icon-networking">
                    <fa-icon :icon="['fas', 'hdd']" />
                </div>
                <div class="card-panel-description">
                    <div class="card-panel-text">
                        Networking
                    </div>
                    <count-to :start-val="0" :end-val="networkingCount" :duration="2600" class="card-panel-num" />
                </div>
            </div>
        </el-col>
        <el-col :xs="12" :sm="12" :lg="8" class="card-panel-col">
            <div class="card-panel">
                <div class="card-panel-icon-wrapper icon-advertising">
                    <fa-icon :icon="['fas', 'tv']" />
                </div>
                <div class="card-panel-description">
                    <div class="card-panel-text">
                        Advertising
                    </div>
                    <count-to :start-val="0" :end-val="advertisingCount" :duration="2600" class="card-panel-num" />
                </div>
            </div>
        </el-col>
    </el-row>

</template>


<script>
import CountTo from 'vue-count-to'
import { getNetworkingCount } from '@/api/networking'
import { getAdvertisingCount } from '@/api/advertising/advertising'
import { getMessageCount } from '@/api/message'

export default {
    components: {
        CountTo
    },
    data() {
        return {
            messageCount: 0,
            networkingCount: 0,
            advertisingCount: 0,
        }
    },
    mounted() {
        this.$nextTick(() => {
            this.setMessageCount();
            this.setNetworkingCount();
            this.setAdvertisingCount();
        });
    },
    methods: {
        setMessageCount() {
            getMessageCount().then(res => {
                this.messageCount = res.data;
            }).catch(res => { })
        },
        setNetworkingCount() {
            getNetworkingCount().then(res => {
                this.networkingCount = res.data;
            }).catch(res => { })
        },
        setAdvertisingCount() {
            getAdvertisingCount().then(res => {
                this.advertisingCount = res.data;
            }).catch(res => { })
        },
    }
}
</script>

<style lang="scss" scoped>
.panel-group {
    margin-top: 18px;

    .card-panel-col {
        margin-bottom: 32px;
    }

    .card-panel {
        height: 108px;
        cursor: pointer;
        font-size: 12px;
        position: relative;
        overflow: hidden;
        color: #666;
        background: #fff;
        box-shadow: 4px 4px 40px rgba(0, 0, 0, .05);
        border-color: rgba(0, 0, 0, .05);

        &:hover {
            .card-panel-icon-wrapper {
                color: #fff;
            }

            .icon-message {
                background: #36a3f7;
            }

            .icon-networking {
                background: #f4516c;
            }

            .icon-advertising {
                background: #34bfa3
            }
        }

        .icon-message {
            color: #36a3f7;
        }

        .icon-networking {
            color: #f4516c;
        }

        .icon-advertising {
            color: #34bfa3
        }

        .card-panel-icon-wrapper {
            float: left;
            margin: 12px 0 0 12px;
            padding: 16px;
            transition: all 0.38s ease-out;
            border-radius: 6px;
            font-size: 48px;
        }

        .card-panel-description {
            float: right;
            font-weight: bold;
            margin: 26px;
            margin-left: 0px;

            .card-panel-text {
                line-height: 18px;
                color: rgba(0, 0, 0, 0.45);
                font-size: 16px;
                margin-bottom: 12px;
            }

            .card-panel-num {
                font-size: 20px;
            }
        }
    }
}

@media (max-width:550px) {
    .card-panel-description {
        display: none;
    }

    .card-panel-icon-wrapper {
        float: none !important;
        width: 100%;
        height: 100%;
        margin: 0 !important;

        .svg-icon {
            display: block;
            margin: 14px auto !important;
            float: none !important;
        }
    }
}
</style>