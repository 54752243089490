import request from '@/http/request'



//获取分页信息
export function getEmailByPage(data) {
    return request({
        url: '/email/get-by-page',
        method: 'post',
        data
    })
}

export function getEmailList() {
    return request({
        url: '/email/list',
        method: 'get',
    })
}

export function getEmailInfo(data) {
    return request({
        url: '/email/info',
        method: 'post',
        data
    })
}

//添加信息
export function addEmail(data) {
    return request({
        url: '/email/add',
        method: 'post',
        data
    })
}

//修改信息
export function updateEmail(data) {
    return request({
        url: '/email/update',
        method: 'post',
        data
    })
}

//删除信息
export function deleteEmail(data) {
    return request({
        url: '/email/delete',
        method: 'post',
        data
    })
}

export function updateEmailStatus(data) {
    return request({
        url: '/email/update-status',
        method: 'post',
        data
    })
}
