import request from '@/http/request'


/**************************** AspectRatio **************************** */
// 获取屏幕比例作为筛选条件
export function getAspectRatioList () {
    return request({
        url: '/aspect-ratio/list',
        method: 'get',
    })
}

export function getAspectRatioByPage (data) {
    return request({
        url: '/aspect-ratio/get-by-page',
        method: 'post',
        data
    })
}

export function addAspectRatio (data) {
    return request({
        url: '/aspect-ratio/add',
        method: 'post',
        data
    })
}

export function updateAspectRatio (data) {
    return request({
        url: '/aspect-ratio/update',
        method: 'post',
        data
    })
}

export function deleteAspectRatio (data) {
    return request({
        url: '/aspect-ratio/delete',
        method: 'post',
        data
    })
}

export function validateAspectRatioExist(data){
    return request({
        url: '/aspect-ratio/validate',
        method: 'post',
        data
    })
}
