import request from '@/http/request'


/******************* firstCategory ***********************/
// 获取所有的一级分类和二级分类
export function getFirstSecondCategoryList () {
    return request({
        url: '/first-category/get-first-second-list',
        method: 'get'
    })
}

// 获取所有一级分类信息
export function getFirstCategoryList () {
    return request({
        url: '/first-category/list',
        method: 'get'
    })
}





