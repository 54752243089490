<template>
    <div id="app">

        <!-- 查询表单 -->
        <el-form :inline="true" :model="queryForm" class="demo-form-inline">
            <el-form-item label="">
                <el-input v-model="queryForm.name" @blur="queryForm.name = $event.target.value.trim()"
                    :placeholder="$t('table.name')" auto-complete="off" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleQuery()">{{ $t('table.search') }}</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleAdd()" v-if="premiss">{{ $t('table.add') }}</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleRecipient()" v-if="premiss">{{ $t('table.recipient')
                    }}</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleCopied()" v-if="premiss">{{ $t('table.copied') }}</el-button>
            </el-form-item>
        </el-form>

        <!-- 数据表格 -->
        <el-table :data="emailList" border :stripe="true" style="width: 100%" max-height="590"
            @selection-change="handleSelectionChange" highlight-selection-row highlight-current-row>
            <el-table-column type="selection" min-width="5%">
            </el-table-column>
            <el-table-column type="index" label="#" :index="handleIndex" min-width="5%">
            </el-table-column>
            <el-table-column prop="name" :label="$t('table.name')" min-width="10%" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="email" :label="$t('table.email')" min-width="15%" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="status" :label="$t('table.status')" min-width="15%" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="createTime" :label="$t('table.createTime')" min-width="15%"
                :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="updateTime" :label="$t('table.updateTime')" min-width="15%"
                :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column :label="$t('table.operation')" min-width="20%" v-if="premiss">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
                        :disabled="scope.row.role == 'super admin'">{{ $t('table.edit') }}</el-button>
                    <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)"
                        :disabled="scope.row.role == 'super admin'">{{ $t('table.delete') }}</el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- 工具栏 -->
        <div class="toolbar">
            <el-button type="danger" size="small" @click="handleDeletes()" v-if="premiss">{{ $t('table.deletes')
                }}</el-button>
            <!-- 分页列表 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
                :current-page="pageNum" :page-sizes="[10, 20, 50]" :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>

        <!-- 添加/编辑表单 -->
        <el-dialog :title="operation ? $t('table.add') : $t('table.edit')" :visible.sync="editFormVisible"
            @close="handleClose()" :destroy-on-close="true">
            <el-form ref="editForm" :model="editForm" :rules="editRules" label-position="right" label-width="100px">
                <el-form-item :label="$t('table.name')" prop="name">
                    <el-input v-model="editForm.name" @blur="editForm.name = $event.target.value.trim()"
                        auto-complete="off" maxlength="50"></el-input>
                </el-form-item>
                <el-form-item :label="$t('table.email')" prop="email">
                    <el-input v-model="editForm.email" @blur="editForm.email = $event.target.value.trim()"
                        auto-complete="off" maxlength="100"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editFormVisible = false">{{ $t('table.cancel') }}</el-button>
                <el-button type="primary" @click="handleEditSubmit('editForm')">{{ $t('table.confirm') }}</el-button>
            </div>
        </el-dialog>

        <el-dialog :title="status ? $t('table.recipient') : $t('table.copied')" :visible.sync="statusVisible">
            <el-transfer v-model="selected" :data="transferData" style="width:100%"></el-transfer>
        </el-dialog>

        <!-- <el-dialog :title="operation ? $t('table.add') : $t('table.edit')" :visible.sync="recipientVisible">
            <el-transfer filterable :filter-method="filterMethod" filter-placeholder="请输入城市拼音" v-model="value"
                :data="data">
            </el-transfer>
        </el-dialog> -->
    </div>
</template>

<script>
import { getEmailByPage, addEmail, updateEmail, deleteEmail ,updateEmailStatus} from "@/api/email"

export default {
    name: 'admin-email',
    computed: {
        editRules() {
            const validateEmail = (rule, value, callback) => {
                const mailReg = /^([a-zA-Z0-9._-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
                // if (!value) {
                //     return callback(new Error('Please enter the email address'))
                // }
                setTimeout(() => {
                    if (mailReg.test(value)) {
                        callback()
                    } else {
                        callback(new Error(this.$t('rules.emailFormat')))
                    }
                }, 100)
            }
            return {
                name: [
                    { required: true, message: this.$t('rules.inputName'), trigger: 'blur' },
                ],
                email: [
                    { required: true, message: this.$t('rules.inputEmail'), trigger: 'blur' },
                    { validator: validateEmail, trigger: 'blur' }
                ],
            }
        }
    },
    data() {
        return {
            loginUser: "",
            loginRole: "",
            premiss: false,
            emailList: [],
            queryForm: {
                name: '',
            },
            pageNum: 1,
            total: 10,
            pageSize: 10,
            pages: 1,
            operation: true,
            editFormVisible: false,
            editForm: {
                id: '',
                name: '',
                email: '',
            },
            deleteIds: [],
            status: 0,
            statusVisible: false,
            transferData: [],
            selected: [1, 4]
        }
    },
    mounted() {
        this.getSession();
        this.setEmailList();
    },
    methods: {
        getSession() {
            this.loginUser = this.$cookies.get("username");
            this.loginRole = this.$cookies.get("role");
            if (this.loginRole == "super admin") {
                this.premiss = true
            }
        },
        setEmailList() {
            let params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                queryForm: this.queryForm
            };
            getEmailByPage(params).then((res) => {
                this.emailList = res.data.content;
                this.total = res.data.totalSize;
            }).catch(function (res) {
                //console.log(res);
            });
        },
        handleIndex(index) {
            const pageNum = this.pageNum // 当前页码
            const pageSize = this.pageSize // 每页条数
            return index + 1 + (pageNum - 1) * pageSize
        },
        handleSelectionChange(val) {
            this.deleteIds = [];
            val.forEach(item => {
                this.deleteIds.push(item.id);
            });
        },
        handleSizeChange(val) {
            if (this.pageSize != val) {
                this.pageSize = val;
                this.pageNum = 1;
                this.setEmailList();
            }
        },
        handleCurrentChange(val) {
            this.pageNum = val;
            this.setEmailList();
        },
        handleQuery() {
            this.pageNum = 1;
            this.setEmailList();
        },
        handleAdd() {
            this.initEditDialog(true)
        },
        initTransferData(status1,status2){

            // let param = {
            //     status1:status1,
            //     status2:status2
            // }
            // updateEmailStatus(param).then((res)=>{
            //     console.log(res.data)
            // })

        },
        handleRecipient() {
            this.statusVisible = true;
            this.initTransferData();
        },
        handleCopied() {
            this.statusVisible = true;
            this.initTransferData();
        },
        delete(ids) {
            this.$confirm(this.$t('message.deleteConfirm'), this.$t('message.tip'), {
                type: "warning"
            }).then(() => {
                deleteEmail(ids).then((res) => {
                    if (res.data >= 1) {
                        this.$message.success({ message: this.$t('message.deleteSuccess') });
                        this.setEmailList();
                    } else {
                        this.$message.error({ message: this.$t('message.deleteFail') });
                    }
                }).catch((res) => {
                    this.$message.warning({ message: res });
                });
            }).catch(() => {
                this.$message.info({ message: this.$t('message.deleteCancel') });
            });
        },
        handleDelete(index, row) {
            let deleteId = [row.id];
            this.delete(deleteId);
        },
        handleDeletes() {
            if (this.deleteIds.length == 0) {
                this.$message.info({ message: this.$t('message.deleteDataSelect') });
            } else {
                this.delete(this.deleteIds);
            }
        },
        initEditDialog(operation) {
            this.operation = operation;
            this.editFormVisible = true;
            this.$nextTick(() => {
                this.$refs.editForm.clearValidate();
            })
        },
        handleEdit(index, row) {
            this.editForm.id = row.id;
            this.editForm.name = row.name;
            this.editForm.email = row.email;
            this.initEditDialog(false);
        },
        handleClose() {
            // 清空添加/编辑表单数据
            Object.keys(this.editForm).forEach(key => (this.editForm[key] = ''));
        },
        handleEditSubmit(form) {
            this.$refs[form].validate((valid) => {
                if (valid) {
                    if (this.operation) {
                        addEmail(this.editForm).then((res) => {
                            if (res.data == 1) {
                                this.$message.success({ message: this.$t('message.addSuccess') });
                                this.pageNum = 1;
                                this.setEmailList();
                            } else {
                                this.$message.error({ message: this.$t('message.addFail') });
                            }
                        }).catch((res) => {
                            this.$message.warning({ message: res });
                        });
                    } else {
                        updateEmail(this.editForm).then((res) => {
                            if (res.data == 1) {
                                this.$message.success({ message: this.$t('message.updateSuccess') });
                                this.setEmailList();
                            } else {
                                this.$message.error({ message: this.$t('message.updateFail') });
                            }
                        }).catch((res) => {
                            this.$message.warning({ message: res });
                        });
                    }
                    this.editFormVisible = false
                } else {
                    //console.log('error submit!!');
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/admin-common.scss";
</style>