<template>
    <div id="app">

        <!-- 查询表单 -->
        <el-form :inline="true" :model="queryForm" class="demo-form-inline">
            <el-form-item label="">
                <el-input v-model="queryForm.username" @blur="queryForm.username = $event.target.value.trim()" :placeholder="$t('table.username')"
                    auto-complete="off" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleQuery()">{{$t('table.search')}}</el-button>
            </el-form-item>
        </el-form>

        <!-- 数据表格 -->
        <el-table :data="recordList" border :stripe="true" style="width: 100%" max-height="590"
         highlight-selection-row highlight-current-row>
            <el-table-column type="index" label="#" :index="handleIndex" min-width="10%">
            </el-table-column>
            <!-- <el-table-column prop="username" :label="$t('table.username')" min-width="20%" :show-overflow-tooltip="true">
            </el-table-column> -->
            <el-table-column prop="content" :label="$t('table.content')" min-width="50%" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="createTime" :label="$t('table.createTime')" min-width="20%" :show-overflow-tooltip="true">
            </el-table-column>
        </el-table>

        <!-- 工具栏 -->
        <div class="toolbar">
            <!-- 分页列表 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
                :current-page="pageNum" :page-sizes="[10, 20, 50]" :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>

    </div>
</template>

<script>
import { getRecordByPage } from "@/api/record"

export default {
    name: 'admin-record',
    data() {
        return {
            recordList: [],
            queryForm: {
                username: '',
            },
            pageNum: 1,
            total: 10,
            pageSize: 10,
            pages: 1,
        }
    },
    mounted() {
        this.setRecordList()
    },
    methods: {
        handleIndex(index) {
            const pageNum = this.pageNum // 当前页码
            const pageSize = this.pageSize // 每页条数
            return index + 1 + (pageNum - 1) * pageSize
        },
        setRecordList() {
            let params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                queryForm: this.queryForm
            };
            getRecordByPage(params).then((res) => {
                this.recordList = res.data.content;
                this.total = res.data.totalSize
            }).catch((res) => {});
        },
        handleQuery() {
            this.pageNum = 1;
            this.setRecordList();
        },
        handleSizeChange(val) {
            if (this.pageSize != val) {
                this.pageSize = val;
                this.pageNum = 1;
                this.setRecordList();
            }
        },
        handleCurrentChange(val) {
            this.pageNum = val;
            this.setRecordList();
        },
    }
}
</script>


<style lang="scss" scoped>
@import "~@/styles/admin-common.scss";
</style>