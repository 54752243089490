<template>
    <div id="app">

        <!-- 查询表单 -->
        <el-form :inline="true" :model="queryForm" class="demo-form-inline">
            <el-form-item label="">
                <el-input v-model="queryForm.name" @blur="queryForm.name = $event.target.value.trim()"
                    :placeholder="$t('table.name')" auto-complete="off" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleQuery()">{{ $t('table.search') }}</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleAdd()" v-if="permiss">{{ $t('table.add') }}</el-button>
            </el-form-item>
        </el-form>

        <!-- 数据表格 -->
        <el-table :data="supportFileList" border :stripe="true" style="width: 100%" max-height="650"
            @selection-change="handleSelectionChange" highlight-selection-row highlight-current-row>

            <el-table-column type="selection" min-width="5%">
            </el-table-column>
            <el-table-column type="index" label="#" :index="handleIndex" min-width="5%">
            </el-table-column>
            <el-table-column prop="name" :label="$t('table.name')" min-width="10%" :show-overflow-tooltip="true">
            </el-table-column>
            <!-- <el-table-column prop="url" :label="$t('table.url')" min-width="15%" :show-overflow-tooltip="true">
            </el-table-column> -->
            <el-table-column prop="size" :label="$t('table.sizeKB')" min-width="10%" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="md5" :label="$t('table.md5')" min-width="10%" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="description" :label="$t('table.description')" min-width="15%"
                :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="createTime" :label="$t('table.createTime')" min-width="15%"
                :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="updateTime" :label="$t('table.updateTime')" min-width="15%"
                :show-overflow-tooltip="true">
            </el-table-column>

            <el-table-column :label="$t('table.operation')" min-width="15%">
                <template slot-scope="scope">
                    <el-button size="mini" type="primary" @click="handleDownload(scope.$index, scope.row)">{{
                        $t('table.download') }}</el-button>
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)" v-if="permiss">{{ $t('table.edit')
                        }}</el-button>
                    <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)" v-if="permiss">{{
                        $t('table.delete') }}</el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- 工具栏 -->
        <div class="toolbar">
            <el-button type="danger" size="small" @click="handleDeletes()" v-if="permiss">{{ $t('table.deletes')
                }}</el-button>
            <!-- 分页列表 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
                :current-page="pageNum" :page-sizes="[10, 20, 50]" :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>

        <!-- 添加/编辑表单 -->
        <el-dialog :title="operation ? $t('table.add') : $t('table.edit')" :visible.sync="editFormVisible"
            @closed="handleClose()" :destroy-on-close="true">
            <el-form ref="editForm" :model="editForm" :rules="editRules" label-position="right" label-width="100px">
                <el-form-item :label="$t('table.name')" prop="name">
                    <el-input v-model="editForm.name" @blur="editForm.name = $event.target.value.trim()"
                        auto-complete="off" maxlength="100"></el-input>
                </el-form-item>
                <el-form-item :label="$t('table.description')" prop="description">
                    <el-input type="textarea" autosize v-model="editForm.description"
                        @blur="editForm.description = $event.target.value.trim()" auto-complete="off" maxlength="100"
                        show-word-limit></el-input>
                </el-form-item>
                <el-form-item :label="$t('table.file')" prop="file">
                    <el-upload class="" drag action="#" :auto-upload="false" :limit="1" :on-change="handleUploadChange"
                        :on-remove="handleUploadRemove" ref="uploadFile" :http-request="handleUpload" accept=""
                        :before-upload="handleBeforeUpload">
                        <i class="el-icon-upload"></i>
                        <div class="el-upload__text">{{ $t('table.uploadFile') }}</div>
                    </el-upload>
                </el-form-item>
                <el-form-item :label="$t('table.sizeKB')" prop="size">
                    <el-input v-model="editForm.size" auto-complete="off" maxlength="20" :disabled="true"></el-input>
                </el-form-item>
                <el-form-item :label="$t('table.md5')" prop="md5">
                    <el-input v-model="editForm.md5" auto-complete="off" maxlength="50" :disabled="true"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editFormVisible = false">{{ $t('table.cancel') }}</el-button>
                <el-button type="primary" @click="handleEditSubmit('editForm')">{{ $t('table.confirm') }}</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import {
    getSupportFileByPage, deleteSupportFile, addSupportFile, updateSupportFile, validateNameExist,
    updateSupportFileAll
} from "@/api/file/supportFile"
import SparkMD5 from 'spark-md5'
import { downloadPost } from '@/utils/fileUtil'

export default {
    name: 'admin-supportFile',
    computed: {
        editRules() {
            const validateName = (rule, value, callback) => {
                if (this.oldName != value) {
                    if (value.length > 20) {
                        callback(new Error(this.$t('rules.lengthRange20')))
                    } else {
                        let param = {
                            name: value
                        }
                        validateNameExist(param).then(res => {
                            if (!res.data) {
                                callback(new Error(this.$t('rules.nameExist')))
                            } else {
                                callback()
                            }
                        }).catch(function (res) {
                            callback(new Error(this.$t('rules.exception')))
                        });
                    }
                }
                else {
                    callback()
                }
            }
            return {
                name: [
                    { required: true, message: this.$t('rules.inputName'), trigger: 'blur' },
                    { validator: validateName, trigger: 'blur' }
                ],
                file: [
                    // { required: true, message: this.$t('rules.selectUploadFile'), trigger: 'change' }
                ]
            }
        }
    },
    data() {

        return {
            loginRole: "",
            permiss: false,
            supportFileList: [],
            queryForm: {
                name: '',
            },
            pageNum: 1,
            total: 10,
            pageSize: 10,
            pages: 1,
            operation: true,
            editFormVisible: false,
            editForm: {
                id: '',
                name: '',
                description: '',
                file: '',
                size: '',
                md5: '',
            },
            oldName: '',
            oldSize: '',
            oldMd5: '',
            deleteIds: [],
            file: '',
        }
    },
    mounted() {
        this.getSession();
        this.setSupportFileList();
    },
    methods: {
        getSession() {
            this.loginRole = this.$cookies.get("role");
            if (this.loginRole == "super admin" || this.loginRole == "advertising admin" || this.loginRole == "networking admin") {
                this.permiss = true;
            } else {
                this.permiss = false;
            }
        },
        handleIndex(index) {
            const pageNum = this.pageNum // 当前页码
            const pageSize = this.pageSize // 每页条数
            return index + 1 + (pageNum - 1) * pageSize
        },
        setSupportFileList() {
            let params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                queryForm: this.queryForm
            };
            getSupportFileByPage(JSON.stringify(params)).then((res) => {
                this.supportFileList = res.data.content;
                this.total = res.data.totalSize;
            }).catch((res) => { });
        },
        delete(ids) {
            this.$confirm(this.$t('message.deleteConfirm'), this.$t('message.tip'), {
                type: "warning"
            }).then(() => {
                deleteSupportFile(ids).then((res) => {
                    if (res.data >= 1) {
                        this.$message.success({ message: this.$t('message.deleteSuccess') });
                        this.setSupportFileList();
                    } else {
                        this.$message.error({ message: this.$t('message.deleteFail') });
                    }
                }).catch((res) => {
                    this.$message.warning({ message: res });
                });
            }).catch(() => {
                this.$message.info({ message: this.$t('message.deleteCancel') });
            });
        },
        handleDelete(index, row) {
            let deleteId = [row.id];
            this.delete(deleteId);
        },
        handleDeletes() {
            if (this.deleteIds.length == 0) {
                this.$message.info({ message: this.$t('message.deleteDataSelect') });
            } else {
                this.delete(this.deleteIds);
            }
        },
        handleSelectionChange(val) {
            this.deleteIds = [];
            val.forEach(item => {
                this.deleteIds.push(item.id);
            });
        },
        handleQuery() {
            this.pageNum = 1;
            this.setSupportFileList();
        },
        initEditDialog(operation) {
            this.operation = operation;
            this.editFormVisible = true;
            this.$nextTick(() => {
                this.$refs.editForm.clearValidate();
            })
        },
        handleAdd() {
            this.disabled = false;
            this.initEditDialog(true)
        },
        handleEdit(index, row) {
            this.editForm.id = row.id;
            this.editForm.name = row.name;
            this.editForm.description = row.description;
            this.editForm.size = row.size;
            this.editForm.md5 = row.md5;
            this.oldName = row.name;
            this.oldSize = row.size;
            this.oldMd5 = row.md5;
            this.initEditDialog(false)
        },
        handleSizeChange(val) {
            if (this.pageSize != val) {
                this.pageSize = val;
                this.pageNum = 1;
                this.setSupportFileList();
            }
        },
        handleCurrentChange(val) {
            this.pageNum = val;
            this.setSupportFileList();
        },
        getFileMD5(file) {
            return new Promise((resolve, reject) => {
                const fileReader = new FileReader();

                fileReader.onload = () => {
                    const arrayBuffer = fileReader.result;
                    const md5Hash = SparkMD5.ArrayBuffer.hash(arrayBuffer);
                    resolve(md5Hash);
                };

                fileReader.onerror = (error) => {
                    reject(error);
                };

                fileReader.readAsArrayBuffer(file);
            });
        },
        handleBeforeUpload(file) {

        },
        async handleUploadChange(file, fileList) {
            if (fileList.length !== 0) {
                this.$refs.editForm.clearValidate('file');
                this.editForm.size = Math.round(file.size / 1024);
                this.editForm.md5 = await this.getFileMD5(file.raw);
                this.editForm.file = file;
                this.file = file
            }
        },
        handleUploadRemove(file, fileList) {
            if (fileList.length === 0) {
                this.editForm.file = null
                this.editForm.size = ""
                this.editForm.md5 = ""
                this.file = null;
            }
            if (!this.operation) {
                this.editForm.size = this.oldSize
                this.editForm.md5 = this.oldMd5
            }
        },
        handleUpload(file) {

        },
        handleDownload(index, row) {
            const fileName = row.url.substring(row.url.lastIndexOf("\\") + 1);
            downloadPost("/support-file/download",row.id,fileName)
        },
        handleClose() {
            // 清空表单
            Object.keys(this.editForm).forEach(key => (this.editForm[key] = ''));
            this.oldName = "";
            this.oldSize = "";
            this.oldMd5 = "";
            this.file = null;
            this.$refs.uploadFile.clearFiles()
        },

        handleEditSubmit(form) {
            this.$refs[form].validate((valid) => {
                if (valid) {
                    this.$refs.uploadFile.submit()

                    if (this.file) {
                        var fd = new FormData() //携带文件必须使用此对象
                        fd.append("file", this.file.raw); //把文件实体添加到表单对象
                        fd.append("name", this.editForm.name);
                        fd.append("description", this.editForm.description);
                        fd.append("size", this.editForm.size);
                        fd.append("md5", this.editForm.md5);

                        if (this.operation) {
                            addSupportFile(fd).then((res) => {
                                if (res.data == 1) {
                                    this.$message.success({ message: this.$t('message.addSuccess') });
                                    this.pageNum = 1;
                                    this.setSupportFileList();
                                    this.editFormVisible = false;
                                } else {
                                    this.$message.error({ message: res.data });
                                }
                            }).catch((res) => {
                                this.$message.warning({ message: res });
                            });
                        } else {
                            fd.append("id", this.editForm.id);
                            updateSupportFileAll(fd).then((res) => {
                                if (res.data == 1) {
                                    this.$message.success({ message: this.$t('message.updateSuccess') });
                                    this.setSupportFileList();
                                    this.editFormVisible = false;
                                } else {
                                    this.$message.error({ message: res.data });
                                }
                            }).catch((res) => {
                                this.$message.error({ message: res });
                            });
                        }
                    } else {
                        if (this.operation) {
                            this.$message.info({ message: this.$t("rules.selectUploadFile") });
                            return;
                        } else {
                            updateSupportFile(this.editForm).then((res) => {
                                if (res.data == 1) {
                                    this.$message.success({ message: this.$t('message.updateSuccess') });
                                    this.setSupportFileList();
                                    this.editFormVisible = false;
                                } else {
                                    this.$message.error({ message: this.$t('message.updateFail') });
                                }
                            }).catch((res) => {
                                this.$message.error({ message: res });
                            });
                        }

                    }
                } else {
                    //console.log('error submit!!');
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/admin-common.scss";
</style>


<style>
.demo-table-expand {
    font-size: 0;
    padding: 0px 50px;
}

.demo-table-expand label {
    width: 90px;
    color: #99a9bf;
    font-weight: bolder;
}

.demo-table-expand .el-form-item {
    margin-right: 0;
    margin-bottom: 0;
    width: 50%;
}
</style>