<template>
    <div id="app">

        <!-- 查询表单 -->
        <el-form :inline="true" :model="queryForm" class="demo-form-inline">
            <el-form-item label="">
                <el-input v-model="queryForm.username" @blur="queryForm.username = $event.target.value.trim()"
                    :placeholder="$t('table.username')" auto-complete="off" clearable></el-input>
            </el-form-item>
            <el-form-item label="">
                <el-select v-model="queryForm.role" :placeholder="$t('table.selectRole')" clearable>
                    <el-option label="super admin" value="super admin"></el-option>
                    <el-option label="networking admin" value="networking admin"></el-option>
                    <el-option label="advertising admin" value="advertising admin"></el-option>
                    <el-option label="user" value="user"></el-option>
                </el-select>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleQuery()">{{ $t('table.search') }}</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleAdd()" v-if="premiss">{{ $t('table.add') }}</el-button>
            </el-form-item>
        </el-form>

        <!-- 数据表格 -->
        <el-table :data="userList" border :stripe="true" style="width: 100%" max-height="590"
            @selection-change="handleSelectionChange" highlight-selection-row highlight-current-row>
            <el-table-column type="selection" min-width="5%">
            </el-table-column>
            <el-table-column type="index" label="#" :index="handleIndex" min-width="5%">
            </el-table-column>
            <el-table-column prop="username" :label="$t('table.username')" min-width="15%"
                :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="role" :label="$t('table.role')" min-width="15%" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="description" :label="$t('table.description')" min-width="15%"
                :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="createTime" :label="$t('table.createTime')" min-width="15%"
                :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="updateTime" :label="$t('table.updateTime')" min-width="15%"
                :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column :label="$t('table.operation')" min-width="15%" v-if="premiss">
                <template slot-scope="scope">
                    <el-button size="mini" @click="handleEdit(scope.$index, scope.row)"
                        :disabled="scope.row.role == 'super admin'">{{ $t('table.edit') }}</el-button>
                    <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)"
                        :disabled="scope.row.role == 'super admin'">{{ $t('table.delete') }}</el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- 工具栏 -->
        <div class="toolbar">
            <el-button type="danger" size="small" @click="handleDeletes()" v-if="premiss">{{ $t('table.deletes')
                }}</el-button>
            <!-- 分页列表 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
                :current-page="pageNum" :page-sizes="[10, 20, 50]" :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>

        <!-- 添加/编辑表单 -->
        <el-dialog :title="operation ? $t('table.add') : $t('table.edit')" :visible.sync="editFormVisible"
            @close="handleClose()" :destroy-on-close="true">
            <el-form ref="editForm" :model="editForm" :rules="editRules" label-position="right" label-width="100px">
                <el-form-item :label="$t('table.username')" prop="username">
                    <el-input v-model="editForm.username" @blur="editForm.username = $event.target.value.trim()"
                        auto-complete="off" minlength="4" maxlength="18"></el-input>
                </el-form-item>
                <el-form-item :label="$t('table.password')" prop="password">
                    <el-input type="password" v-model.trim="editForm.password" auto-complete="off"
                        show-password></el-input>
                </el-form-item>
                <el-form-item :label="$t('table.role')" prop="role">
                    <el-select v-model="editForm.role" :placeholder="$t('table.selectRole')" style="width:100%;">
                        <el-option label="networking admin" value="networking admin"></el-option>
                        <el-option label="advertising admin" value="advertising admin"></el-option>
                        <el-option label="user" value="user"></el-option>
                    </el-select>
                </el-form-item>
                <el-form-item :label="$t('table.description')" prop="description">
                    <el-input type="textarea" autosize v-model="editForm.description"
                        @blur="editForm.description = $event.target.value.trim()" auto-complete="off" maxlength="100"
                        show-word-limit></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editFormVisible = false">{{ $t('table.cancel') }}</el-button>
                <el-button type="primary" @click="handleEditSubmit('editForm')">{{ $t('table.confirm') }}</el-button>
            </div>
        </el-dialog>

    </div>
</template>

<script>
import { getUserByPage, addUser, validateUsernameExist, updateUser, deleteUser } from "@/api/user"

export default {
    name: 'admin-user',
    computed: {
        editRules() {
            const validateUsername = (rule, value, callback) => {
                if (this.oldUsername != value) {
                    if (value.length < 4 || value.length > 18) {
                        callback(new Error(this.$t('rules.usernameLengthRange')))
                    } else {
                        let param = {
                            name: value
                        }
                        validateUsernameExist(param).then(res => {
                            if (!res.data) {
                                callback(new Error(this.$t('rules.usernameExist')))
                            } else {
                                callback()
                            }
                        }).catch(function (res) {
                            callback(new Error(this.$t('rules.exception')))
                        });
                    }
                }
                else {
                    callback()
                }
            }
            const validatePassword = (rule, value, callback) => {
                if (this.oldPassword != value) {
                    if (value.length < 8 || value.length > 12) {
                        callback(new Error(this.$t('rules.passwordLengthRange')))
                    } else {
                        let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,12}$/;
                        if (!reg.test(value)) {
                            callback(new Error(this.$t('rules.passwordFormat')))
                        } else {
                            callback()
                        }
                    }
                } else {
                    callback()
                }
            }
            return {
                username: [
                    { required: true, message: this.$t('rules.inputUsername'), trigger: 'blur' },
                    { validator: validateUsername, trigger: 'blur' },
                ],
                password: [
                    { required: true, message: this.$t('rules.inputPassword'), trigger: 'blur' },
                    { validator: validatePassword, trigger: 'blur' }
                ],
                role: [
                    { required: true, message: this.$t('rules.selectRole'), trigger: 'change' }
                ],
            }
        }
    },
    data() {

        return {
            loginUser: "",
            loginRole: "",
            premiss: false,
            userList: [],
            queryForm: {
                username: '',
                role: ''
            },
            pageNum: 1,
            total: 10,
            pageSize: 10,
            pages: 1,
            operation: true,
            editFormVisible: false,
            editForm: {
                id: '',
                username: '',
                password: '',
                role: '',
                description: ''
            },
            oldUsername: '',
            oldPassword: '',
            deleteIds: [],
        }
    },
    mounted() {
        this.getSession();
        this.setUserList();
    },
    methods: {
        getSession() {
            this.loginUser = this.$cookies.get("username");
            this.loginRole = this.$cookies.get("role");
            if (this.loginRole == "super admin") {
                this.premiss = true
            }
        },
        handleIndex(index) {
            const pageNum = this.pageNum // 当前页码
            const pageSize = this.pageSize // 每页条数
            return index + 1 + (pageNum - 1) * pageSize
        },
        setUserList() {
            let params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                queryForm: this.queryForm
            };
            getUserByPage(params).then((res) => {
                this.userList = res.data.content;
                this.total = res.data.totalSize;
            }).catch(function (res) {
                //console.log(res);
            });
        },
        handleEdit(index, row) {
            this.editForm.id = row.id;
            this.editForm.username = row.username;
            this.editForm.password = row.password;
            this.editForm.role = row.role;
            this.editForm.description = row.description;
            this.oldUsername = row.username;
            this.oldPassword = row.password;
            this.initEditDialog(false);
        },
        delete(ids) {
            this.$confirm(this.$t('message.deleteConfirm'), this.$t('message.tip'), {
                type: "warning"
            }).then(() => {
                deleteUser(ids).then((res) => {
                    if (res.data >= 1) {
                        this.$message.success({ message: this.$t('message.deleteSuccess') });
                        this.setUserList();
                    } else {
                        this.$message.error({ message: this.$t('message.deleteFail') });
                    }
                }).catch((res) => {
                    this.$message.warning({ message: res });
                });
            }).catch(() => {
                this.$message.info({ message: this.$t('message.deleteCancel') });
            });
        },
        handleDelete(index, row) {
            if (this.loginRole == "super admin" & this.loginUser == row.username) {
                this.$alert(this.$t('message.deleteSelf'), this.$t('message.tip'), {
                    type: 'warning',
                    callback: action => { }
                });
            } else {
                let deleteId = [row.id];
                this.delete(deleteId);
            }
        },
        handleDeletes() {
            if (this.deleteIds.length == 0) {
                this.$message.info({ message: this.$t('message.deleteDataSelect') });
            } else {
                this.delete(this.deleteIds);
            }
        },
        handleSelectionChange(val) {
            this.deleteIds = [];
            val.forEach(item => {
                this.deleteIds.push(item.id);
            });
        },
        handleQuery() {
            this.pageNum = 1;
            this.setUserList();
        },
        initEditDialog(operation) {
            this.operation = operation;
            this.editFormVisible = true;
            this.$nextTick(() => {
                this.$refs.editForm.clearValidate();
            })
        },
        handleAdd() {
            this.initEditDialog(true)
        },
        handleSizeChange(val) {
            if (this.pageSize != val) {
                this.pageSize = val;
                this.pageNum = 1;
                this.setUserList();
            }
        },
        handleCurrentChange(val) {
            this.pageNum = val;
            this.setUserList();
        },
        handleClose() {
            // 清空添加/编辑表单数据
            Object.keys(this.editForm).forEach(key => (this.editForm[key] = ''));
            this.oldUsername = '';
            this.oldPassword = '';
        },
        handleEditSubmit(form) {
            this.$refs[form].validate((valid) => {
                if (valid) {
                    if (this.operation) {
                        addUser(this.editForm).then((res) => {
                            if (res.data == 1) {
                                this.$message.success({ message: this.$t('message.addSuccess') });
                                this.pageNum = 1;
                                this.setUserList();
                            } else {
                                this.$message.error({ message: this.$t('message.addFail') });
                            }
                        }).catch((res) => {
                            this.$message.warning({ message: res });
                        });
                    } else {
                        updateUser(this.editForm).then((res) => {
                            if (res.data == 1) {
                                this.$message.success({ message: this.$t('message.updateSuccess') });
                                this.setUserList();
                            } else {
                                this.$message.error({ message: this.$t('message.updateFail') });
                            }
                        }).catch((res) => {
                            this.$message.warning({ message: res });
                        });
                    }
                    this.editFormVisible = false
                } else {
                    //console.log('error submit!!');
                }
            });
        }
    }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/admin-common.scss";
</style>