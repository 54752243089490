import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
//import "@/mock";
import '@/icons'
import i18n from './i18n'

import fontawesome from '@fortawesome/fontawesome'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'
import solid from '@fortawesome/fontawesome-free-solid'
import regular from '@fortawesome/fontawesome-free-regular'
import brands from '@fortawesome/fontawesome-free-brands'


import Cookies from "@/utils/cookie"
Vue.prototype.$cookies = Cookies;


fontawesome.library.add(solid)
fontawesome.library.add(regular)
fontawesome.library.add(brands)
Vue.component('fa-icon', FontAwesomeIcon)

Vue.config.productionTip = false
Vue.use(ElementUI)



new Vue({
  router,
  store,
  i18n,
  render: h => h(App)
}).$mount('#app')
