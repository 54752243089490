<template>
    <div class="headbar" :class="isCollapse ? 'menu-bar-collapse-width' : 'menu-bar-width'">
        <!-- 导航菜单隐藏显示切换 -->
        <!-- <span class="hamburg">
        <el-menu class="el-menu-demo" :background-color="themeColor" text-color="#fff" :active-text-color="themeColor" mode="horizontal">
            <el-menu-item index="1" @click="onCollapse"><hamburger :isActive="$store.state.app.isCollapse"></hamburger></el-menu-item>
        </el-menu>
      </span> -->
        <span class="collapse-switcher" @click.prevent="collapse()">
            <i :class="isCollapse ? 'el-icon-s-unfold' : 'el-icon-s-fold'"></i>
        </span>

        <!-- 导航右侧工具栏 -->
        <span class="toolbar">
            <!-- 语言切换 -->
            <LangSelector class="lang-selector"></LangSelector>
            <!-- 主题切换 -->
            <ThemePicker class="theme-picker"></ThemePicker>

            <!-- 全屏切换 -->
            <screenfull class="screenfull" />
            <!-- 登录信息菜单 -->
            <el-dropdown class="user-info-dropdown" trigger="hover">
                <span class="el-dropdown-link"><img :src="this.avatar" /> {{ username }}</span>
                <el-dropdown-menu slot="dropdown">
                    <el-dropdown-item @click.native="handleUpdate()">{{ $t("common.updatePassword") }}</el-dropdown-item>
                    <el-dropdown-item divided @click.native="handleLogout()">{{ $t("common.logout")
                        }}</el-dropdown-item>
                </el-dropdown-menu>
            </el-dropdown>
        </span>


        <el-dialog title="修改密码" :visible.sync="editFormVisible" @close="handleClose()" :destroy-on-close="true">
            <el-form ref="editForm" :model="editForm" :rules="editRules" label-position="right" label-width="auto"
                class="item">
                <el-form-item :label="$t('common.oldPassword')" prop="oldPassword">
                    <el-input type="oldPassword" v-model.trim="editForm.oldPassword" auto-complete="off"
                        show-password></el-input>
                </el-form-item>
                <el-form-item :label="$t('common.newPassword')" prop="password">
                    <el-input type="password" v-model.trim="editForm.password" auto-complete="off"
                        show-password></el-input>
                </el-form-item>
                <el-form-item :label="$t('common.confirmPassword')" prop="confirmPassword">
                    <el-input type="confirmPassword" v-model.trim="editForm.confirmPassword" auto-complete="off"
                        show-password></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="editFormVisible = false">{{$t('table.cancel')}}</el-button>
                <el-button type="primary" @click="handleEditSubmit('editForm')">{{$t('table.confirm')}}</el-button>
            </div>
        </el-dialog>
    </div>

</template>

<script>
import ThemePicker from "@/components/ThemePicker"
import LangSelector from "@/components/LangSelector"
import { mapState } from 'vuex'
import Screenfull from '@/components/Screenfull'
import Cookies from "js-cookie";
import { validatePasswordCorrect, updatePassword } from "@/api/user"


export default {
    components: {
        ThemePicker,
        LangSelector,
        Screenfull
    },
    data() {
        const validateOldPassword = (rule, value, callback) => {
            const params = {
                username: this.username,
                password: value
            }
            validatePasswordCorrect(params).then(res => {
                if (!res.data) {
                    callback(new Error(this.$t('rules.passwordError')))
                } else {
                    callback()
                }
            }).catch(function (res) {
                callback(new Error(this.$t('rules.exception')))
            });
        }
        const validatePassword = (rule, value, callback) => {
            if (value.length < 8 || value.length > 12) {
                callback(new Error(this.$t('rules.passwordLengthRange')))
            } else {
                let reg = /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,12}$/;
                if (!reg.test(value)) {
                    callback(new Error(this.$t('rules.passwordFormat')))
                } else {
                    callback()
                }
            }
        }
        const validateConfirmPassword = (rule, value, callback) => {
            if (this.editForm.password != this.editForm.confirmPassword) {
                callback(new Error(this.$t('rules.passwordConfirmError')))
            } else {
                callback()
            }
        }
        return {
            username: "",
            avatar: "",
            //activeIndex: '0',
            editFormVisible: false,
            editForm: {
                username: '',
                oldPassword: '',
                password: '',
                confirmPassword: ''
            },
            editRules: {
                oldPassword: [
                    { required: true, message: this.$t('rules.inputOldPassword'), trigger: 'blur' },
                    { validator: validateOldPassword, trigger: 'blur' },
                ],
                password: [
                    { required: true, message: this.$t('rules.inputNewPassword'), trigger: 'blur' },
                    { validator: validatePassword, trigger: 'blur' }
                ],
                confirmPassword: [
                    { required: true, message: this.$t('rules.inputConfirmPassword'), trigger: 'blur' },
                    { validator: validateConfirmPassword, trigger: 'blur' }
                ],
            },
        };
    },
    computed: {
        ...mapState({
            isCollapse: state => state.app.isCollapse,
        })
    },
    methods: {
        // selectNavBar(key, keyPath) {
        //     console.log(key, keyPath)
        // },
        //折叠导航栏
        collapse: function () {
            this.$store.commit("collapse")
        },
        handleUpdate() {
            this.editFormVisible = true;
            this.editForm.username = this.username;
        },
        handleClose() {
            Object.keys(this.editForm).forEach(key => (this.editForm[key] = ''));
        },
        handleEditSubmit(form) {
            this.$refs[form].validate((valid) => {
                if (valid) {
                    updatePassword(this.editForm).then((res) => {
                        if(res.data == 1){
                            this.$message.success({ message: this.$t('message.updateSuccess') });
                        }else{
                            this.$message.error({ message: this.$t('message.updateFail') });
                        }       
                    }).catch((res) => {
                        this.$message.warning({ message: res });
                    });

                    this.editFormVisible = false
                } else {
                    //console.log('error submit!!');
                }
            });
        },
        //退出登录
        handleLogout() {
            var _this = this;
            this.$confirm(this.$t('message.confirmLogout'), this.$t('message.tip'), {
                type: "warning"
            }).then(() => {
                
                this.$cookies.remove("username")
                this.$router.push("/login");
            }).catch(() => { });
        },
    },
    mounted() {
        var user = this.$cookies.get("username")
        if (user) {
            this.username = user;
            this.avatar = require("@/assets/avatar.jpg");
        }
    }
};
</script>

<style scoped lang="scss">
@import "~@/styles/variables.scss";

.headbar {
    position: absolute;
    left: 220px;
    transition: left .3s;
    right: 0px;
    //height: 60px;
    //line-height: 60px;
    background: #fff;
    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: #ddd;
    box-shadow: 3px 3px 5px rgba(0, 0, 0, 0.1);

    .collapse-switcher {
        float: left;
        cursor: pointer;
        color: #666;
        background: #fff;
        width: 60px;
        height: 60px;
        line-height: 60px;
        text-align: center;
        font-size: 20px;

    }

    .collapse-switcher:hover {
        background-color: #f0f0f0 !important;
    }

    .toolbar {
        float: right;
        height: 60px;
        line-height: 60px;
        color: #666;

        .theme-picker {
            padding: 0px 10px 0px 10px;
            height: 60px;
        }

        .lang-selector {
            padding: 0px 10px 0px 10px;
            font-size: 20px;
            height: 60px;
            cursor: pointer;
        }

        .screenfull {
            padding: 0px 10px 0px 10px;
            font-size: 20px;
            cursor: pointer;
        }

        .user-info-dropdown {
            font-size: 20px;
            padding: 0px 10px 0px 10px;
            cursor: pointer;

            img {
                width: 40px;
                height: 40px;
                border-radius: 10px;
                margin: 10px 10px 10px 10px;
                float: right;
            }
        }
    }
}

.menu-bar-width {
    left: 220px !important;
}

.menu-bar-collapse-width {
    left: 60px !important;
}
</style>
