<template>
    <div id="app">

        <!-- 查询表单 -->
        <el-form :inline="true" :model="queryForm" class="demo-form-inline">
            <el-form-item label="">
                <el-input v-model="queryForm.name" @blur="queryForm.name = $event.target.value.trim()"
                    :placeholder="$t('table.name')" auto-complete="off" clearable></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="handleQuery()">{{ $t('table.search') }}</el-button>
            </el-form-item>
        </el-form>

        <!-- 数据表格 -->
        <el-table :data="messageList" border :stripe="true" style="width: 100%" max-height="590" highlight-selection-row
            @selection-change="handleSelectionChange" highlight-current-row>
            <el-table-column type="selection" min-width="5%">
            </el-table-column>
            <el-table-column type="index" label="#" :index="handleIndex" min-width="5%">
            </el-table-column>
            <el-table-column prop="name" :label="$t('table.name')" min-width="10%" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="company" :label="$t('table.company')" min-width="10%" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="tel" :label="$t('table.tel')" min-width="10%" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="ext" :label="$t('table.ext')" min-width="10%" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="email" :label="$t('table.email')" min-width="10%" :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="suggestion" :label="$t('table.suggestion')" min-width="20%"
                :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column prop="createTime" :label="$t('table.createTime')" min-width="10%"
                :show-overflow-tooltip="true">
            </el-table-column>
            <el-table-column :label="$t('table.operation')" min-width="10%">
                <template slot-scope="scope">
                    <el-button size="mini" type="primary" @click="handleDetail(scope.$index, scope.row)">{{
                        $t('table.detail') }}</el-button>
                    <el-button size="mini" type="danger" @click="handleDelete(scope.$index, scope.row)" v-if="permiss">{{
                        $t('table.delete') }}</el-button>
                </template>
            </el-table-column>
        </el-table>

        <!-- 工具栏 -->
        <div class="toolbar">
            <el-button type="danger" size="small" @click="handleDeletes()"
                v-if="permiss">{{ $t('table.deletes') }}</el-button>
            <!-- 分页列表 -->
            <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange" background
                :current-page="pageNum" :page-sizes="[10, 20, 50]" :page-size="pageSize"
                layout="total, sizes, prev, pager, next, jumper" :total="total">
            </el-pagination>
        </div>

        <!-- 详情 -->
        <el-dialog :title="$t('table.detail')" :visible.sync="detailsVisible" :destroy-on-close="true" width="60%">
            <el-descriptions title="" :column="1" border :contentStyle="{ 'white-space': 'pre-wrap' }"
                :label-style="{ 'white-space': 'nowrap' }">
                <el-descriptions-item :label="$t('table.name')">{{ message.name }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('table.company')">{{ message.company }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('table.tel')">{{ message.tel }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('table.ext')">{{ message.ext }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('table.email')">{{ message.email }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('table.suggestion')">{{ message.suggestion }}
                </el-descriptions-item>
                <el-descriptions-item :label="$t('table.createTime')">{{ message.createTime }}
                </el-descriptions-item>
            </el-descriptions>
        </el-dialog>
    </div>
</template>

<script>
import { getMessageByPage, deleteMessage } from "@/api/message"

export default {
    name: 'admin-message',
    data() {
        return {
            loginRole: "",
            permiss: false,
            messageList: [],
            queryForm: {
                name: '',
            },
            pageNum: 1,
            total: 10,
            pageSize: 10,
            pages: 1,
            deleteIds: [],
            detailsVisible:false,
            message:{
                name:"",
                company:"",
                tel:"",
                ext:"",
                email:"",
                suggestion:"",
                createTime:"",
            }
        }
    },
    mounted() {
        this.getSession();
        this.setMessageList();
    },
    methods: {
        getSession() {
            this.loginRole = this.$cookies.get("role");
            if (this.loginRole == "super admin" || this.loginRole == "advertising admin" || this.loginRole == "networking admin") {
                this.permiss = true;
            } else {
                this.permiss = false;
            }
        },
        handleIndex(index) {
            const pageNum = this.pageNum // 当前页码
            const pageSize = this.pageSize // 每页条数
            return index + 1 + (pageNum - 1) * pageSize
        },
        setMessageList() {
            let params = {
                pageNum: this.pageNum,
                pageSize: this.pageSize,
                queryForm: this.queryForm
            };
            getMessageByPage(params).then((res) => {
                this.messageList = res.data.content;
                this.total = res.data.totalSize;
            }).catch((res) => {
                //console.log(res);
            });
        },
        delete(ids) {
            this.$confirm(this.$t('message.deleteConfirm'), this.$t('message.tip'), {
                type: "warning"
            }).then(() => {
                deleteMessage(ids).then((res) => {
                    if (res.data >= 1) {
                        this.$message.success({ message: this.$t('message.deleteSuccess') });
                        this.setMessageList();
                    } else {
                        this.$message.success({ message: this.$t('message.deleteFail') });
                    }
                }).catch((res) => {
                    this.$message.warning({ message: res });
                });
            }).catch(() => {
                this.$message.info({ message: this.$t('message.deleteCancel') });
            });
        },
        handleDelete(index, row) {
            let deleteId = [row.id];
            this.delete(deleteId);
        },
        handleDeletes() {
            if (this.deleteIds.length == 0) {
                this.$message.info({ message: this.$t('message.deleteDataSelect') });
            } else {
                this.delete(this.deleteIds);
            }
        },
        handleSelectionChange(val) {
            this.deleteIds = [];
            val.forEach(item => {
                this.deleteIds.push(item.id);
            });
        },
        handleQuery() {
            this.pageNum = 1;
            this.setMessageList();
        },
        handleSizeChange(val) {
            if (this.pageSize != val) {
                this.pageSize = val;
                this.pageNum = 1;
                this.setMessageList();
            }
        },
        handleCurrentChange(val) {
            this.pageNum = val;
            this.setMessageList();
        },
        handleDetail(index, row){
            this.detailsVisible = true;
            this.message.name = row.name;
            this.message.company = row.company;
            this.message.tel = row.tel;
            this.message.ext = row.ext;
            this.message.email = row.email;
            this.message.suggestion = row.suggestion;
            this.message.createTime = row.createTime;
        }
    }
}
</script>


<style lang="scss" scoped>
@import "~@/styles/admin-common.scss";
</style>