import request from '@/http/request'


//获取分页数据
export function getImageBannerByPage(data) {
    return request({
        url: '/image-banner/get-by-page',
        method: 'post',
        data
    })
}

export function addImageBanner(data) {
    return request({
        url: '/image-banner/add',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data
    })
}

export function updateImageBanner(data) {
    return request({
        url: '/image-banner/update',
        method: 'post',
        data
    })
}

export function updateImageBannerFile(data) {
    return request({
        url: '/image-banner/update-file',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data
    })
}

export function deleteImageBanner(data) {
    return request({
        url: '/image-banner/delete',
        method: 'post',
        data
    })
}

//获取所有数据
export function getImageBannerList() {
    return request({
        url: '/image-banner/list',
        method: 'get',
    })
}

//根据id查询单条数据
export function getImageBannerInfo(data) {
    return request({
        url: '/image-banner/info',
        method: 'post',
        data
    })
}

//根据id查询单条数据
export function validateNameExist(data) {
    return request({
        url: '/image-banner/validate',
        method: 'post',
        data
    })
}

export function getImageBannerByName(data) {
    return request({
        url: '/image-banner/get-by-name',
        method: 'post',
        data
    })
}




