import request from '@/http/request'


// 获取二级分类（id）下所有网通信息
export function getNetworkingFilter (data) {
    return request({
        url: '/networking/filter',
        method: 'post',
        data
    })
}

// 根据id获取单条网通信息
export function getNetworkingInfo (data) {
    return request({
        url: '/networking/info',
        method: 'post',
        data
    })
}

export function getNetworkingByPage (data) {
    return request({
        url: '/networking/get-by-page',
        method: 'post',
        data
    })
}

export function addNetworking (data) {
    return request({
        url: '/networking/add',
        method: 'post',
        data
    })
}

export function deleteNetworking (data) {
    return request({
        url: '/networking/delete',
        method: 'post',
        data
    })
}

export function updateNetworking (data) {
    return request({
        url: '/networking/update',
        method: 'post',
        data
    })
}

// 获取网通数据总量
export function getNetworkingCount () {
    return request({
        url: '/networking/total',
        method: 'get',
    })
}

// 获取各二级分类下的网通数据量
export function getNetworkingPieChart () {
    return request({
        url: '/networking/pie-chart',
        method: 'get',
    })
}

export function validateNameExist(data){
    return request({
        url: '/networking/validate',
        method: 'post',
        data
    })
}

export function getNetworkingDetail(data){
    return request({
        url: '/networking/detail',
        method: 'post',
        data
    })
}

export function updateNetworkingCover(data){
    return request({
        url: '/networking/update-cover',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data
    })
}

export function addNetworkingExhibit(data){
    return request({
        url: '/networking/add-exhibit',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data
    })
}

export function deleteNetworkingExhibit(data){
    return request({
        url: '/networking/delete-exhibit',
        method: 'post',
        data
    })
}

export function updateNetworkingExhibit(data){
    return request({
        url: '/networking/update-exhibit',
        method: 'post',
        data
    })
}
