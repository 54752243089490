import request from '@/http/request'


//获取分页数据
export function getVideoBannerByPage(data) {
    return request({
        url: '/video-banner/get-by-page',
        method: 'post',
        data
    })
}

export function addVideoBanner(data) {
    return request({
        url: '/video-banner/add',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data
    })
}

export function updateVideoBanner(data) {
    return request({
        url: '/video-banner/update',
        method: 'post',
        data
    })
}

export function updateVideoBannerFile(data) {
    return request({
        url: '/video-banner/update-file',
        method: 'post',
        headers: {
            'Content-Type': 'multipart/form-data'
        },
        data
    })
}

export function deleteVideoBanner(data) {
    return request({
        url: '/video-banner/delete',
        method: 'post',
        data
    })
}

//获取所有数据
export function getVideoBannerList() {
    return request({
        url: '/video-banner/list',
        method: 'get',
    })
}

//根据id查询单条数据
export function getVideoBannerInfo(data) {
    return request({
        url: '/video-banner/info',
        method: 'post',
        data
    })
}

//根据id查询单条数据
export function validateNameExist(data) {
    return request({
        url: '/video-banner/validate',
        method: 'post',
        data
    })
}

//根据id查询单条数据
export function getVideoBannerByName(data) {
    return request({
        url: '/video-banner/get-by-name',
        method: 'post',
        data
    })
}


